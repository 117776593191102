import React from "react";

function IconKey(props) {
  return (
    <svg
      className="icon icon--key"
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.319 0L5.28 5.119a3.26 3.26 0 0 0-1.218-.244A4.023 4.023 0 0 0 0 8.938 4.023 4.023 0 0 0 4.063 13a4.023 4.023 0 0 0 4.062-4.063 3.68 3.68 0 0 0-.244-1.3L8.937 6.5V4.875h1.626V3.25h1.624L13 2.437V0h-2.681zM3.656 9.75c-.65 0-1.219-.569-1.219-1.219 0-.65.57-1.219 1.22-1.219s1.218.57 1.218 1.22S4.306 9.75 3.656 9.75z"
        fillRule="nonzero"
      />
    </svg>
  );
}

IconKey.defaultProps = {
  width: 13,
  height: 13
};

export default IconKey;
