import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom'
import Link from 'components/link'
import Input from 'components/input'
import Checkbox from 'components/checkbox'
import Button from 'components/button'
import Tos from 'components/tos'
import Form from 'components/form'
import FormMsg from 'components/form-msg'
import Instructions from 'components/instructions';
import __ from "language";
import FormError from 'components/form-error';
import { ssoActivateOffer, ssoRegistrationState } from 'api'
import { SSO_ACTIVATE_OFFER_SUCCESS } from 'reducers/user/activate_offer';
import { routeToExternal } from 'modules/utils'

import './registration-user.scss'
import { stat } from 'fs';

async function sha256(str) {
  const buf = await crypto.subtle.digest("SHA-256", new TextEncoder("utf-8").encode(str));
  return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
}

const RegistrationSubscriptionForm = ({ssoActivateOffer, ssoRegistrationState, client, user, offer, product, status, subscription, account}) => {
  const [error, setError] = useState();
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [postnumber, setPostnumber] = useState('')
  const [city, setCity] = useState('')
  const [reference, setReference] = useState('')
  const [activation_code, setActivationCode] = useState('')
  const [formIsValid, setFormValidity] = useState(false);
  const [email, setEmail] = useState('')
  const [email_active, set_email_active] = useState(false)
  const [address_active, set_address_active] = useState(true)
  const [validation_message, set_validation_message] = useState('')
  const [delivery_address_required, set_delivery_address_required] = useState(true)
  
  const [invoice_separate_address, set_invoice_separate_address] = useState(false)
  const [invoice_name, set_invoice_name] = useState('')
  const [invoice_address, set_invoice_address] = useState('')
  const [invoice_postnumber, set_invoice_postnumber] = useState('')
  const [invoice_city, set_invoice_city] = useState('')
  const history = useHistory()

  useEffect(() => {
    set_delivery_address_required(offer && offer.delivery_address_required)  
    set_address_active(offer && offer.delivery_address_required)
  },
  [offer && offer.delivery_address_required]
  )

  useEffect(() => {

    (async () => {
        const activation_code_hash = activation_code ? await sha256(activation_code.trim()) : '';
        const activation_code_list = offer.activation_code?.split(',') ?? [];
        setFormValidity( 
          (!offer.activation_code || activation_code_list.includes(activation_code_hash)) && 
          (!address_active || ( name.length && address.length && postnumber.length && city.length )) &&
          (!email_active || ( email.length ))
        )
    })()
    }, 
    [name, address, postnumber, city, reference, email, email_active, address_active, activation_code]
  )

  const handleGotoProduct = () => {
    history.push('sso_login')

  }

  const pending = account && account.pending || subscription && subscription.pending
  return <>
    {status === SSO_ACTIVATE_OFFER_SUCCESS ?
      <>
        <div>
          <div><FormMsg msg={pending ? __.registration_subscription_pending_feedback : __.registration_subscription_complete_feedback} /></div>
          <div className="global__flex__row">
            <Button onClick={handleGotoProduct} label={`${__.global__goto_to} ${product.name}`} />
            <Button theme="link" to="mine-sider" label={__.global__user_profile} />
          </div>
        </div>
      </>
    : offer.activation_code ?
      <>
        <Form className="registration">
          <Instructions>{__.registration_subscription_activation_code_instruction}</Instructions>
          <Input type="text" error={error} name="activation_code" placeholder={__.global__activation_code_placeholder} onChange={e => setActivationCode(e.target.value)} defaultValue={activation_code} label={__.global__activation_code}/>
          <div className="form__field form__field__group form__field__group--right">            
            <Button disabled={!formIsValid} disabledMessage={__.registration_subscription_order_validation_message} onClick={() => { ssoActivateOffer({offer_id: offer.id, reference, activation_code, name, address, postnumber, city, email, email_active, address_active, invoice_separate_address, invoice_name, invoice_address, invoice_postnumber, invoice_city })} } label={__.registration_subscription_order} />            
          </div>

          <FormError error={error} />

        </Form>
      </>
    :
      <>
        <Form className="registration">
          { delivery_address_required &&
            <>
              <Instructions>{__.registration_subscription_delivery_adress_instruction}</Instructions>
              {/* <Checkbox onChange={e => set_address_active(e.target.checked)} defaultValue={address_active} label={__.global__address_active} /> */}
              <Input required type="text" error={error} name="name" placeholder={__.global__name_placeholder} onChange={e => setName(e.target.value)} defaultValue={name} label={__.global__name}/>
              <Input required type="text" error={error} name="address" placeholder={__.global__address_placeholder} onChange={e => setAddress(e.target.value)} defaultValue={address} label={__.global__address}/>
              <Input required type="text" error={error} name="postnumber" placeholder={__.global__postnumber_placeholder} onChange={e => setPostnumber(e.target.value)} defaultValue={postnumber} label={__.global__postnumber}/>
              <Input required type="text" error={error} name="city" placeholder={__.global__city_placeholder} onChange={e => setCity(e.target.value)} defaultValue={city} label={__.global__city}/>
              <Checkbox onChange={e => set_email_active(e.target.checked)} defaultValue={email_active} label={__.global__email_active} />
              <Input type="text" required={email_active} error={error} name="email" placeholder={__.global__email_placeholder} onChange={e => setEmail(e.target.value)} defaultValue={email} label={__.global__email}/>
            </>
          }
          <Instructions>{__.registration_subscription_invoice_reference_instruction}</Instructions>
          <Input type="text" error={error} name="reference" placeholder={__.global__reference_placeholder} onChange={e => setReference(e.target.value)} defaultValue={reference} label={__.global__reference}/>

          <Checkbox onChange={e => set_invoice_separate_address(e.target.checked)} defaultValue={invoice_separate_address} label={__.registration_subscription_add_invoice_address_instruction} />
          { invoice_separate_address && 
            <>
              <Input required type="text" error={error} name="invoice_name" placeholder={__.global__name_placeholder} onChange={e => set_invoice_name(e.target.value)} defaultValue={invoice_name} label={__.global__name}/>
              <Input required type="text" error={error} name="invoice_address" placeholder={__.global__address_placeholder} onChange={e => set_invoice_address(e.target.value)} defaultValue={invoice_address} label={__.global__address}/>
              <Input required type="text" error={error} name="invoice_postnumber" placeholder={__.global__postnumber_placeholder} onChange={e => set_invoice_postnumber(e.target.value)} defaultValue={invoice_postnumber} label={__.global__postnumber}/>
              <Input required type="text" error={error} name="invoice_city" placeholder={__.global__city_placeholder} onChange={e => set_invoice_city(e.target.value)} defaultValue={invoice_city} label={__.global__city}/>
            </>
          }
          <div className="form__field form__field__group form__field__group--right">            
            <Button disabled={!formIsValid} disabledMessage={__.registration_subscription_order_validation_message} onClick={() => { ssoActivateOffer({offer_id: offer.id, reference, name, address, postnumber, city, email, email_active, address_active, invoice_separate_address, invoice_name, invoice_address, invoice_postnumber, invoice_city })} } label={__.registration_subscription_order} />            
          </div>

          <FormError error={error} />
        </Form>
      </>
    }
  </>
}


function mapStateToProps(state) {

  return {
    client: state.client,
    user: state.registration && state.registration.user || {},
    offer: state.registration && state.registration.offer || {},
    product: state.registration && state.registration.product || {},
    subscription: state.registration && state.registration.subscription || {},
    account: state.registration && state.registration.account || {},
    status: state.user && state.user.activate_offer && state.user.activate_offer.status || ''
  }
}

export default connect(
  mapStateToProps,
  { ssoActivateOffer, ssoRegistrationState }
)(withRouter(RegistrationSubscriptionForm));

