import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import Input from 'components/input'
import Button from 'components/button'
import IconSupplier from "components/icons/icon-supplier";
import Spinner from 'components/spinner';
import Checkblock from 'components/checkblock'
import FormMsg from 'components/form-msg'
import { ssoAddUserToAccount, ssoCreateAccount, ssoRegistrationState } from 'api'
import { SSO_ADD_USER_TO_ACCOUNT_CREATE_SUCCESS } from 'reducers/user/add_to_account';

import OrgQuery from './org-query';
import __ from "language";
import './registration-user.scss'


const OrgAssign = ({org, status, registration, ssoAddUserToAccount, ssoCreateAccount, ssoRegistrationState}) => {

  if (!registration || !registration.user) return <Spinner />

  const {orgnr, company, address, city } = org

  const handleAssign = () => {
    ssoAddUserToAccount({orgnr})
  }

  if (status === SSO_ADD_USER_TO_ACCOUNT_CREATE_SUCCESS) {
    return <>
      <div className="form__field form__field__group">
        <FormMsg msg={__.registration_subscription_orgnumber_search_account_added_success} />
      </div>
      <Button onClick={() => ssoRegistrationState({})} label={__.registration_subscription_orgnumber_search_account_added_button} />
    </>
  }
  return (
    <>
      <Checkblock check={org && org.company} title={org && org.company}>
        <span>{org && org.orgnr}</span>
      </Checkblock>
      <Button type="button" className="registration__get-org__btn" theme="secondary" onClick={handleAssign} label={__.registration_subscription_orgnumber_search_account_apply} />
    </>
  )
}

function mapStateToProps(state) {
  return {
    registration: state.registration,
    ...state.user.add_to_account
  }
}

export default connect(
  mapStateToProps,
  { ssoAddUserToAccount, ssoCreateAccount, ssoRegistrationState }
)(OrgAssign);


