import React, { useRef } from 'react'

import './checkbox.scss'

const Checkbox = ({name, label, onChange, defaultValue, ...rest }) => {

  const inputEl = useRef();

  return <label className="form__field checkbox" htmlFor={name}>
    <input className="checkbox__input" ref={inputEl}  id={name} type="checkbox" name={name} onChange={onChange} checked={defaultValue} {...rest} />
    <span className="checkbox__label" >{label}</span>
  </label>
}

export default Checkbox
