import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './button.scss'
import { ssoNotification } from 'api'

const Button = ({ssoNotification, className, history, loading, name, to, label, onClick, disabled, disabledMessage, icon, size = 'normal', theme = 'filled', type = "text"}) => {

  const handleClick = (e) => {
    if (disabledMessage && disabled) {
      ssoNotification(disabledMessage)
      return;
    }
    if (to) {
      history.push(to)
    } else if (!disabled ) {
      return onClick(e)
    }
  }

  const classes = [
    'button',
    `button__${theme}`,
    `button--${size}`,
    loading ? 'button--loading' : '',
    !label ? 'button--empty' : '',
    disabled ? 'button--inactive' : ''
  ].join(' ')

  return (
    <button disabled={disabled && !disabledMessage} className={classes} type={type} name={name} onClick={handleClick}>
      {icon ? icon : null}
      {label}
    </button>
  )
}


export default connect(null,{ ssoNotification })(withRouter(Button));

