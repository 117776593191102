import React from 'react'
import './form-msg.scss'
import __ from 'language';

const FormMsg = ({msg}) => {
  const message = msg && __[msg] || msg;
  return message
    ? (<div className={`form__field form-msg`} dangerouslySetInnerHTML={{ __html: message }} >
    </div>)
    : null
}
export default FormMsg
