import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Input from 'components/input'
import Field from 'components/field'
import Button from 'components/button'
import Form from 'components/form'
import RouteHeader from 'components/route-header';
import Spinner from 'components/spinner';
import Link from 'components/link'
import { ssoUserProfile, ssoUserProfileSave } from 'api'
import __ from 'language'
import './user-profile.scss'

import { Modal, ModalContent, ModalActions, useModal } from 'components/modal'
import { Section } from 'components/section'
import { UpdatePassword } from 'containers/update-password'
import { UpdateUser } from 'containers/update-user'
import IconKey from "components/icons/icon-key";
import Pending from "components/pending";
import { IconAccount, IconUser, IconCompany } from "assets";
import { MyServices } from 'containers/my-services'

const UserProfile = ({profile, ssoUserProfile, ssoUserProfileSave, account}) => {

  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [error, setError] = useState()
  const [activePasswordModal, setActivePasswordModal, togglePasswordModal] = useModal()

  useEffect( () => {
    ssoUserProfile()
  }, [])

  if ( !profile ) return <Spinner />;

  return <>
    <RouteHeader>{__.profile_header}</RouteHeader>
    <Section title={__.profile_general_information} subtitle={__.profile_general_information_about} icon={<IconUser />} actions={<><UpdatePassword /><UpdateUser/></>}>
      <Field defaultValue={profile.name} label={__.global__name}/>
      <Field defaultValue={profile.email} label={__.global__email}/>
    </Section>

    { account && <Section title={__.profile_organisation} subtitle={__.profile_organisation_about} icon={<IconCompany />}>
        <div className="user-profile__organisation">
          <h3>{account.company}</h3>
          { account.pending ?
            <Pending label={__.pending_membership}/>
            :
            <>
              { ( account.firstname || account.lastname ) && <div className="user-profile__organisation__text">{__.profile_organisation_responsible}: {account.firstname} {account.lastname}</div> }
              <div className="user-profile__organisation__text">{__.profile_organisation_email}: {account.email}</div>
            </>
          }
        </div>
      </Section> }

    <Section title={__.profile_services} subtitle={__.profile_services_about} icon={<IconKey />}>
      <MyServices />
    </Section>
  </>
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
    account: state.profile && state.profile.account || null

  }
}

export default connect(
  mapStateToProps,
  { ssoUserProfile, ssoUserProfileSave }
)(withRouter(UserProfile));
