import React  from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import queryString from 'query-string'

import { connect } from 'react-redux';

import { Tos } from 'containers/tos'

import SimpleLayout from  './simple-layout'
import Client from  './client'
import './app.scss'
import 'global.scss'
import 'flexbox.scss'

const legacyRouteMap = {
  '/sso/login': 'sso_login',
  '/sso/logout': 'sso_logout',
  '/sso/close': 'sso_close',
  '/abonner': 'abonner',
  '/abonner/kb': 'abonner/kb',
  '/abonner/ldb': 'abonner/ldb',
  '/mine-sider': 'mine-sider',
  '/': 'mine-sider',
}
const LegacyRoute = ({location}) => {

  var { client_uuid, clientId, returnUrl } = queryString.parse(location.search)

  client_uuid = clientId || client_uuid || process.env.REACT_APP_KR_SSO_CLIENT_ID || process.env.REACT_APP_SSO_SSO_CLIENT_ID

  const params = {returnUrl}
  const params_string = queryString.stringify(params)

  var new_route = legacyRouteMap[location.pathname]
  return <Redirect to={`/${client_uuid}/${new_route}?${params_string}`} />

}
const App = () => {
  return (
    <Switch>
      <SimpleLayout path="/tos" component={Tos} />
      <Route path="/abonner" component={LegacyRoute} />
      <Route path="/mine-sider" component={LegacyRoute} />
      <Route path="/sso/login" component={LegacyRoute} />
      <Route path="/sso/logout" component={LegacyRoute} />
      <Route path="/sso/close" component={LegacyRoute} />
      <Route path="/:client_uuid" component={Client} />
      <Route exact path="/" component={LegacyRoute} />
    </Switch>
  )
}


function mapStateToProps(state) {
  return {
    client: state.client,
  }
}

export default connect(
  mapStateToProps,
  {  }
)(withRouter(App));
