import React from 'react'
import { IconPending } from "assets";
import './pending.scss'


const Pending = ({label}) => {

  return <div className={`pending`}>
    <IconPending />
    {label}
  </div>
}
export default Pending
