import React from 'react'

import Checkblock from 'components/checkblock'
import './registration-user.scss'

export default ({org}) => (
  <Checkblock check={org && org.company} title={org && org.company}>
    <span>{org && org.orgnr}</span>  
  </Checkblock>
  
)
