import React from 'react'
import IconKummunalRapport from "components/icons/icon-kommunal-rapport";
import IconPerson from "components/icons/icon-person";
import IconCross from "components/icons/icon-cross";
import IconLogout from "components/icons/icon-logout";

import { NavLink } from 'react-router-dom'
import __ from "language";

import './site-header.scss'

const SiteHeader = ({className, profile, client}) => {

  const loginUrl = `/${client.uuid}/logg-inn`
  const logoutUrl = `/${client.uuid}/sso_logout`

  return <header className={`site-header`}>
    <NavLink className="site-header__logo" to="" tabIndex="-1"><IconKummunalRapport /></NavLink>
    <nav className={`site-header__nav`}>
      {profile ?
        <>
          <div className={`site-header__nav__item`}>
            <NavLink to="mine-sider" tabIndex="-1">
              <span className={`site-header__nav__item__label`} title={__.profile_header}>{profile}</span>
              <IconPerson />
            </NavLink>
          </div>
          <div className={`site-header__nav__item`}>
            <NavLink className={`site-header__nav__item__label`} to={logoutUrl} tabIndex="-1">
              <span className={`site-header__nav__item__label`} title={__.global__logout} >{__.global__logout}</span>
              <IconLogout />
            </NavLink>
          </div>
        </>
      :
        <>
          <div className={`site-header__nav__item`}>
            <NavLink className={`site-header__nav__item__label`} to={loginUrl} tabIndex="-1">
              <span className={`site-header__nav__item__label`} >{__.global__login}</span>
              <IconPerson />
            </NavLink>
          </div>
        </>
      }
      {!client.pending && !client.error &&
        <div className={`site-header__nav__item`}>
          <a href={client.home_url} tabIndex="-1" title={__.global__back_to + ' ' + client.name}>
            <span className={`site-header__nav__item__label`}>{__.global__back_to} {client.name}</span>
            <IconCross />
          </a>
        </div>
      }
    </nav>
  </header>
}

export default SiteHeader