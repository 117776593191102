
export const SSO_VALIDATION_EMAIL = 'SSO_VALIDATION_EMAIL'
export const SSO_VALIDATION_EMAIL_RESPONSE = 'SSO_VALIDATION_EMAIL_RESPONSE'


export default (state = {pending: true}, action) => {
  switch(action.type){
    case SSO_VALIDATION_EMAIL:
    return {
      ...state,
      ...action.payload,
      pending: true,
    }
    case SSO_VALIDATION_EMAIL_RESPONSE:
    return {
      ...state,
      ...action.payload,
      pending: false,
    }
    default:
    return state;
  }
}