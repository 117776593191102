import React from 'react';
import IconKummunalRapport from "components/icons/icon-kommunal-rapport";
import __ from 'language'

import './products.scss';

const ProductTitle = ({product, level = '1' }) => {
  const componentClass = 'product';

  const Heading = 'h' + level;
  return (
    <Heading className={`${componentClass}__title`}>
      { product.logo ? 
        <img src={product.logo } />
        :
        product.title 
      }
    </Heading>
  )
}

export default ProductTitle
