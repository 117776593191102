import React, { useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Form from 'components/form'
import Input from 'components/input'
import Button from 'components/button'
import FormError from 'components/form-error'
import __ from "language";
import { ssoUpdateInvoiceSettings } from 'api'
import { Modal, ModalContent, ModalActions, useModal } from 'components/modal'
import { IconPencil } from "assets";
import './update-invoice-settings.scss'


const UpdateInvoiceSettings = ({account, match, history, status, ssoUpdateInvoiceSettings}) => {

  const [invoice_email, setInvoiceEmail] = useState(account.invoice_email)

  const [error, setError] = useState('')
  const [formIsValid, setFormValidity] = useState(false)
  const [active, setActive, toggle] = useModal()

  const handleUpdate = (invoice_email) => {
    ssoUpdateInvoiceSettings({invoice_email})
    toggle()
  }

  return (
    <>
      <Button theme="secondary" onClick={toggle} label={__.admin__update_invoice_settings_button_label} icon={<IconPencil />}/>

      <Form>
        <Modal active={active} toggle={toggle} title={__.admin__update_invoice_settings_button_label}>
          <ModalContent>
            <Input required type="text" error={error} name="invoice_email" onChange={e => setInvoiceEmail(e.target.value)} defaultValue={invoice_email} label={__.admin__update_invoice_settings_invoice_email}/>
            <FormError error={error} />
          </ModalContent>
          <ModalActions>
            <Button theme="secondary" onClick={toggle} label={__.global__cancel} />
            <Button onClick={() => handleUpdate(invoice_email) } label={__.global__save} />
          </ModalActions>
        </Modal>
      </Form>
    </>
)}

function mapStateToProps(state) {
  return {
    account: (state.profile && state.profile.account) || {}
  }
}

export default connect(
  mapStateToProps,
  { ssoUpdateInvoiceSettings }
)(withRouter(UpdateInvoiceSettings));
