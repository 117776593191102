
export const SSO_ACCOUNT_MEMBERS = 'SSO_ACCOUNT_MEMBERS'
export const SSO_ACCOUNT_MEMBERS_SUCCESS = 'SSO_ACCOUNT_MEMBERS_SUCCESS'
export const SSO_ACCOUNT_MEMBERS_ERROR = 'SSO_ACCOUNT_MEMBERS_ERROR'

export const SSO_ACCOUNT_MEMBERS_SAVE = 'SSO_ACCOUNT_MEMBERS_SAVE'
export const SSO_ACCOUNT_MEMBERS_SAVE_SUCCESS = 'SSO_ACCOUNT_MEMBERS_SAVE_SUCCESS'
export const SSO_ACCOUNT_MEMBERS_SAVE_ERROR = 'SSO_ACCOUNT_MEMBERS_SAVE_ERROR'

export const login = (email, password) => {
  return {
    type: SSO_ACCOUNT_MEMBERS,
    payload: {email, password}
  }
}

export default (state = { list: [],  pending: true,  error: false }, action) => {
  switch(action.type){
    case SSO_ACCOUNT_MEMBERS:
    return {
      ...state,
      list: [],
      pending: true,
      error: false
    }
    case SSO_ACCOUNT_MEMBERS_SUCCESS:
    return {
      ...state,
      list: action.payload,
      pending: false,
      error: false
    }
    case SSO_ACCOUNT_MEMBERS_ERROR:
    return {
      ...state,
      ...action.payload,
      list: [],
      pending: false,
    }
    case SSO_ACCOUNT_MEMBERS_SAVE:
    return {
      ...state,
      pending: true,
      error: false
    }
    case SSO_ACCOUNT_MEMBERS_SAVE_SUCCESS:
    return {
      ...state,
      ...action.payload,
      pending: false,
      error: false
    }
    case SSO_ACCOUNT_MEMBERS_SAVE_ERROR:
    return {
      ...state,
      ...action.payload,
      pending: false,
    }
    default:
    return state;
  }
}