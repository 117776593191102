import React from 'react'

import __ from "language";
import './registration-offer.scss'

// const formatter = new Intl.NumberFormat("nb", {
//   minimumFractionDigits: 0
// })
const formatter = {
  format: (value) => value.replace(/(?=(?:...)*$)/g, ' ').trim()
}

export const priceFormater = (value) => formatter.format(value)
export const periodFormater = (value) => {
  return __[`period__per_${ value.toLowerCase()}`]
}

export default ({offer}) => {
  const value = `${offer.amount}`
  const period_unit_code = offer.period_unit_code
  const period_description = offer.period_description || periodFormater(period_unit_code)
  return <span className="registration-offer-price">
    {offer.amount > 0 && <>
      {priceFormater(value)} {' '}
      NOK {' '}
    </>}
    <span className="registration-offer-price__duration">{period_description}</span>
  </span>
}
