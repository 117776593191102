import * as Cookies from "js-cookie";

const opt = { path: '/', expires: 7 }
const cookieName = 'kr-sso'

export default {
  get: () => Cookies.get(cookieName, opt),
  set: (data) => {
    Cookies.set(cookieName, data, opt)
  },
  remove: () => {
    Cookies.remove(cookieName, opt)
  },
  headers: (headers) => {
    const jwt = Cookies.get(cookieName, opt)
    const auth = jwt ? {'Authorization': `Bearer ${jwt}`} : {}
    const nocache = {'Pragma': 'nocache'}
    return {...headers, ...auth, ...nocache}
  }
}
