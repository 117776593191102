import React from 'react';
import { ssoSelectProduct } from 'api'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import './products.scss';
import { IconChecked } from "assets";

const ProductAvis = ({products, selected, ssoSelectProduct}) => {
  const componentClass = 'product-avis';
  const classes = [
    componentClass
  ].join(' ')

  const setProduct = (productCode, offer) => {
    ssoSelectProduct({
      product: productCode,
      offer: offer,
      type: selected.type
    });
  }

  return (
    <div className={classes}>
      {products.map((product, index) => {
        const features = product.feature ? product.feature.match(/[^\r\n]+/g) : []
        return (
          <div className={`${componentClass}__item`} key={index}>
            <img src={product.icon} />
            {/* <h3 className={`${componentClass}__subtitle`}>{product.title}</h3> */}
            <h2 className={`${componentClass}__title`}>{product.title}</h2>            
            {features.length > 0 ?
              <div className={`${componentClass}__list`}>
                {features.map((feature, index) => {
                    return (
                      <div key={index}  className={`${componentClass}__list__item`}>
                        <IconChecked />
                        {feature}
                      </div>
                    )
                  })
                }
              </div>
            : null }
            <div className={`${componentClass}__actions`}>
              {product.offers.sort((a, b) => a.position - b.position).map((offer, index) => {
                  return (
                    <Link
                      to={`/${product.client_uuid}/registrere?offer_uuid=${offer.uuid}`}
                      onClick={() => setProduct(product.product_number, offer.uuid)}
                      key={index}
                      className={`${componentClass}__button product__button`}>
                        <span className={`${componentClass}__button__name`}>{offer.title}</span>
                        <span className={`${componentClass}__button__amount`}>
                          {offer.amount} <span className={`${componentClass}__button__currency`}>NOK</span>
                          {offer.period_description && <div className={`${componentClass}__button__period`}>{offer.period_description}</div> }
                        </span>
                    </Link>
                  )}
                )}
            </div>
          </div>
        )}
      )}
    </div>

  )
}

function mapStateToProps(state) {
  return {
    // profile: state.profile,
    selected: state.product.selected
  }
}

export default connect(
  mapStateToProps,
  { ssoSelectProduct }
)(withRouter(ProductAvis));
