import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom'
import queryString from 'query-string'

import __ from "language";
import RegistrationUser from './registration-user';
import RegistrationOrg from './registration-org';
import RegistrationAccount from './registration-account';
import RegistrationSubscription from './registration-subscription';
import Infobox from 'components/infobox'
import Button from 'components/button'
import { uuid } from 'modules/utils'
import Instructions from 'components/instructions';
import RegistrationOffer from './registration-offer';
import RouteHeader from 'components/route-header';
import Spinner from 'components/spinner';
import { ssoProduct, ssoRegistrationState } from 'api'
import './registration-user.scss'
import RegistrationWizard from './registration-wizard';

const RegistrationState = ({state, offer, product, account, subscription, returnUrl}) => {
  // <Instructions>{isBasis ? __.registration_user_instruction : __.registration_subscription_subtitle}</Instructions>

  return <>
    { state === 'spinner' && <Spinner /> }
    { state === 'registration_basis_user' && <RegistrationUser returnUrl={returnUrl} /> }
    { state === 'registration_plus_user' && <RegistrationUser offer={offer} returnUrl={returnUrl} /> }
    { state === 'registration_plus_account' && <><RegistrationWizard step={2} state={state} account={account} product={product} /><RegistrationAccount /></> }
    { state === 'registration_plus_subscription' && <><RegistrationWizard step={3} state={state} account={account} product={product} /><RegistrationSubscription offer={offer} /></> }
    { state === 'registration_plus_subscription_pending' && <><RegistrationWizard step={3} state={state} account={account} product={product} /><RegistrationSubscription offer={offer} /></> }
    { state === 'registration_basis_user_complete' &&
      <Infobox>
        <div>{__.registration_basis_user_instructions}</div>
        <div className="global__flex__row">
          <Button to="client_login" label={__.global__goto_to_service} />
          <Button theme="link" to="mine-sider" label={__.global__user_profile} />
        </div>
      </Infobox>
      }
    { state === 'registration_plus_user_complete' &&
      <>
        <RegistrationWizard state={state} account={account} product={product} />
        <Infobox>
          <div>{__.registration_plus_user_instructions}</div>
          <div className="global__flex__row">
            <Button to="client_login" label={__.global__goto_to_service} />
            <Button theme="link" to="mine-sider" label={__.global__user_profile} />
          </div>
        </Infobox>
      </>
      }
    { state === 'org' && <RegistrationOrg formData={{ name:'', email:'', org:'' }} /> }
  </>
}

const registerModuleState = (user, account, offer, product, subscription) => {

  if (offer && offer.id) {
    // PLUS
    if (!user || !user.id ) {
      return 'registration_plus_user'
    }
    if (!account || !account.id) {
      return 'registration_plus_account'
    }
    if (subscription && subscription.activated)
    {
      return 'registration_plus_user_complete'
    }
    return 'registration_plus_subscription_pending'
  }
  else {
    // BASIS
    if (!user || !user.id ) {
      return 'registration_basis_user'
    }
    return 'registration_basis_user_complete'
  }
  return 'spinner'
}

const Registration = ({pending, cart_uuid, offer_uuid, client_uuid, user, account, offer, product, subscription, location, ssoProduct, ssoRegistrationState}) => {

  useEffect( () => {
    ssoProduct()
  }, [])

  useEffect( () => {
    if (!cart_uuid) {
      window.location.search = window.location.search + '&cart_uuid=' + uuid()
    }
  }, [cart_uuid])

  useEffect( () => {
    ssoRegistrationState({offer_uuid, client_uuid, cart_uuid })
  }, [offer_uuid, client_uuid, cart_uuid])

  if (pending) return <Spinner />

  const returnUrl = `${location.pathname}${location.search}`
  const isBasis = !offer
  const state = registerModuleState(user, account, offer, product, subscription)
  return <>
    <RouteHeader>{isBasis ? __.registration_user_header : <RegistrationOffer offer={offer} product={product}/> }</RouteHeader>
    {/* <Instructions>{isBasis ? '' : offer}</Instructions> */}
    <RegistrationState state={state} offer={offer} product={product} account={account} subscription={subscription} returnUrl={returnUrl}/>
  </>
}

function mapStateToProps(state, ownProps) {

  const { offer_uuid, client_uuid, cart_uuid } = ownProps.location ? queryString.parse(ownProps.location.search) : {};
  var props = {pending: true, offer_uuid, client_uuid }
  return {
    ...state.registration,
    cart_uuid,
    offer_uuid,
    client_uuid
  }
}

export default connect(
  mapStateToProps,
  { ssoProduct, ssoRegistrationState }
)(withRouter(Registration));

