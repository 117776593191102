import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Pending from "components/pending";

import __ from "language";
import './registration-wizard.scss'

const RegistrationWizardStep = ({number, state, active, title, info}) => {

  const cls = `registration-wizard__step registration-wizard__step--${state} registration-wizard__step--${active ? 'active' : 'inactive'}`
  const pending = state === 'pending';
  return <div className={cls}>
    <div className="registration-wizard__step__number">{number}</div>
    <div className="registration-wizard__step__label">
      <div className="registration-wizard__step__label__title" data-pending={pending ? 'true' : 'false'}>{title}</div>
      <div className="registration-wizard__step__label__info">{info}</div>
    </div>
  </div>
}
const RegistrationWizard = ({step, account, product, subscription, user, offer}) => {
  const subscription_state =
    !subscription || !subscription.id ? 'not-started' :
     subscription && subscription.activated ? 'complete'
    : 'pending'

    const user_title = __.global__user
    const account_state =
    !account ? 'not-started' :
    account && account.pending ? 'pending'
    : 'complete'

    const account_title = account_state === 'pending' ? __.pending_membership : __.global__company

    const account_delivery = subscription_state === 'pending' ? __.pending_subscription : 'Leverans och fakturering' //__.global__delivery_and_billing_info

    const product_state = !product ? 'not-started' : 'complete'

  return <>
    <div className="registration-wizard__title">
      { offer.activation_code ?
        __.registration_subscription_activation_code_subtitle
        :
        __.registration_subscription_subtitle
      }
    </div>
    <div className="registration-wizard">
      <RegistrationWizardStep number="1" state={product_state} active={step == 1} title={user_title} info={user && user.name} />
      <RegistrationWizardStep number="2" state={account_state} active={step == 2} title={account_title} info={account && account.company} />
      { offer.activation_code ?
        <RegistrationWizardStep number="3" state={subscription_state} active={step == 3} title={__.global__activation_code} info={offer && offer.title} />
        :
        <RegistrationWizardStep number="3" state={subscription_state} active={step == 3} title={account_delivery} info={offer && offer.title} />
      }
    </div>
  </>
}


function mapStateToProps(state) {
  return {
    ...state.registration,
  }
}

export default connect(mapStateToProps)(withRouter(RegistrationWizard));

