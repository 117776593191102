import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Form from 'components/form'
import Input from 'components/input'
import Button from 'components/button'
import FormError from 'components/form-error'
import __ from "language";
import { ssoUserProfileSave } from 'api'
import { Modal, ModalContent, ModalActions, useModal } from 'components/modal'
import { IconPencil } from "assets";
import './update-user.scss'

const UpdateUser = ({match, history, status, ssoUserProfileSave, profile}) => {

  const [name, setName] = useState(profile.name)
  const [error, setError] = useState('')
  const [formIsValid, setFormValidity] = useState(false)
  const [activeUserModal, setActiveUserModal, toggleUserModal] = useModal()


  useEffect( () => {
    setName(profile.name)
  }, [profile.name])

  useEffect( () => {
  }, [activeUserModal])

  const handleUpdate = (name) => {
    ssoUserProfileSave(name)
    toggleUserModal()
  }

  return (
    <>
      <Button theme="secondary" onClick={toggleUserModal} label={__.admin__update_user} icon={<IconPencil />}/>

      <Form>
        <Modal active={activeUserModal} toggle={toggleUserModal} title={__.admin__update_user}>
          <ModalContent>
            <Input required type="name" error={error} name="name" onChange={e => setName(e.target.value)} defaultValue={name} label={__.global__name} placeholder={__.global__name_placeholder}/>
            <FormError error={error} />
          </ModalContent>
          <ModalActions>
            <Button theme="secondary" onClick={toggleUserModal} label={__.global__cancel} />
            <Button onClick={() => handleUpdate(name) } label={__.global__save} />
          </ModalActions>
        </Modal>
      </Form>
    </>
)}

function mapStateToProps(state) {
  return {
    ...state.updatePassword,
    profile: state.profile || {}
  }
}

export default connect(
  mapStateToProps,
  { ssoUserProfileSave }
)(withRouter(UpdateUser));
