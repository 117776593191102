import React from 'react'
import './section.scss'

export const Section = ({title, subtitle, icon, children, actions, theme = 'standard' }) => {

  return (
    <section className={`section section--${theme}`}>
      <div className={`section__header`}>
        <h3 className={`section__header__title`}>
          <span className={`section__header__title__icon`}>
            {icon}
          </span>
          {title}
        </h3>
        <div className={`section__header__subtitle`}>
          {subtitle}
        </div>
      </div>
      <div className={`section__content`}>
        { children }
        { actions &&
          <div className={`section__content__actions`}>
            {actions}
          </div>
        }
      </div>
    </section>
  )
}
