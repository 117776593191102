import React from 'react'
import './tos.scss'


export default () => {
  return (
    <div className="tos">
      <h1>Bruksvilkår</h1>
      <h3>1. Bruk av tjenesten</h3>
      <p>Kommunebarometeret er lisensbasert. Du kan ikke dele passord med andre, videreselge eller bruke tjenesten kommersielt. Det er ulovlig og straffbart å skaffe seg uautorisert tilgang til tjenesten (hacking), å kopiere innhold for annet enn privat bruk samt å legge ut innhold på internett.</p>
      <h3>2. Kommunal Rapports ansvar for tjenesten</h3>
      <p>Kommunal Rapport AS (KR) er ansvarlig for alt innholdet i tjenesten og for at tjenesten virker. KR er ikke ansvarlig for feil ved internettforbindelsen eller feil på enheten du bruker. Oppdager du feil på tjenesten ber vi om at du kontakter oss.</p>
      <h3>3. Betaling</h3>
      <p>Tjenesten er abonnement/lisens-betalt, og løper fra det registreres første gang, og fornyes automatisk årlig til det sies det opp.</p>
      <h3>4. Oppsigelse</h3>
      <p>Du kan si opp tjenesten din via epost, avtalen løper da ut den perioden/året du har forhåndsbetalt. Oppsigelsesfrist er tre uker før automatisk fornyelse av abonnementet/lisensen.</p>
      <h3>5. Kontakt</h3>
      <p>For å kontakte oss om Kommunebarometeret: barometer@kommunal-rapport.no</p>
    </div>
  )
}
