import React from 'react'
import { connect } from 'react-redux';
import { ssoNotificationDismiss } from 'api'
import { withRouter } from 'react-router-dom'
import __ from "language";
import './notification.scss'
import { IconPlus } from 'assets'

const Notification = ({ssoNotificationDismiss, notification}) => {
  const active = notification.open && notification.message && notification.message.length > 0
  const classes = `notification notification--${active ? 'open':'close'}`
  const text = __[notification.message] || notification.message
  return (
    <div className={classes}>
      <div className="notification__left"></div>
      <div className="notification__content">{text}</div>
      <div className="notification__right" onClick={() => ssoNotificationDismiss()}><IconPlus /></div>
    </div>
  )
}


function mapStateToProps(state) {
  return {
    notification: state.notification
  }
}

export default connect(
  mapStateToProps,
  { ssoNotificationDismiss }
)(withRouter(Notification));

