import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Form from 'components/form'
import Input from 'components/input'
import Dropdown from 'components/dropdown'
import Button from 'components/button'
import FormError from 'components/form-error'
import __ from "language";
import { ssoUpdateUserSubscription, ssoUpdateDeliveryAddress } from 'api'
import { Modal, ModalContent, ModalActions, useModal } from 'components/modal'
import { IconPencil } from "assets";
import './update-user-subscription.scss'

const UpdateUserSubscription = ({account_members, ssoUpdateDeliveryAddress, ssoUpdateUserSubscription, subscription}) => {

  const [user_id, set_user_id] = useState(subscription.user_id)
  const [subscription_id, set_subscription_id] = useState(subscription.subscription_id)
  const [activeUserModal, setActiveUserModal, toggleUserModal] = useModal()
  const [error, setError] = useState('')

  const [address, setAddress] = useState(subscription.address)
  const [postnumber, setPostnumber] = useState(subscription.postnumber)
  const [city, setCity] = useState(subscription.city)
  const [name, setName] = useState(subscription.name)
  const [co, setCo] = useState(subscription.co)

  const handleUpdate = (user_id, subscription_id) => {
    ssoUpdateUserSubscription({user_id, subscription_id})
    if (subscription.delivery_address_required) {
      const {address_id} = subscription
      ssoUpdateDeliveryAddress({subscription_id, address_id, address, postnumber, city, name, co})
    }
    toggleUserModal()
  }

  const options = account_members.map( s => ({id: s.id, label: s.email}))

  return (
    <>
      <Button onClick={toggleUserModal} label={''} theme="link" icon={<IconPencil/>}/>

      <Form>
        <Modal active={activeUserModal} toggle={toggleUserModal} title={__.admin__update_user_subscription}>
          <ModalContent>
            <Dropdown name="user_id" onChange={e => set_user_id(e.target.value)} defaultValue={user_id} label={__.global__user} options={options}/>
            { subscription.delivery_address_required &&
              <>
                <Input required type="text" error={error} name="name" onChange={e => setName(e.target.value)} defaultValue={name} label={__.global__name}/>
                <Input required type="text" error={error} name="address" onChange={e => setAddress(e.target.value)} defaultValue={address} label={__.global__address}/>
                <div className="update_user_subscription__row">
                  <Input required type="text" error={error} name="postnumber" onChange={e => setPostnumber(e.target.value)} defaultValue={postnumber} label={__.global__postnumber}/>
                  <Input required type="text" error={error} name="city" onChange={e => setCity(e.target.value)} defaultValue={city} label={__.global__city}/>
                </div>
                <Input required type="text" error={error} name="co" onChange={e => setCo(e.target.value)} defaultValue={co} label={__.global__co}/>
              </>
            }
            <FormError error={error} />
          </ModalContent>
          <ModalActions>
            <Button theme="secondary" onClick={toggleUserModal} label={__.global__cancel} />
            <Button onClick={() => handleUpdate(user_id, subscription_id) } label={__.global__save} />
          </ModalActions>
        </Modal>
      </Form>
    </>
)}

function mapStateToProps(state) {
  return {
    ...state.updatePassword,
    profile: state.profile || {},
    account_members: state.account_members && state.account_members.list || {}
  }
}

export default connect(
  mapStateToProps,
  { ssoUpdateDeliveryAddress, ssoUpdateUserSubscription }
)(withRouter(UpdateUserSubscription));
