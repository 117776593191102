import React from 'react'

import __ from "language";
import RegistrationUserForm from './registration-user-form';
import './registration-user.scss'


export default ({offer, returnUrl}) => {

  return <>
    <RegistrationUserForm offer={offer} returnUrl={returnUrl}/>
  </>
}
