import React from 'react';
import { Link, useParams } from 'react-router-dom';
import __ from 'language'
import { KrDigitalImage, KrAvisImage, KbImage, LdbImage } from "assets";
import { PRODUCT_CODES } from 'modules/constants'
import ProductTitle from './product-title';

import './products.scss';

const ProductList = ({products, title}) => {
  const {client_uuid} = useParams()
  const componentClass = 'other-products';
  const classes = [
    componentClass
  ].join(' ')

  return (
    <div className={classes}>
      <div className="content content--center">
        <h2 className={`${componentClass}__title`}>{title}</h2>
        <div className={`${componentClass}__list`}>
          {products.map(product => {
            if (!product.feature) {
              return null;
            }
            const productCode = product.code.toLowerCase()

            return (
              <div className={`${componentClass}__list__item`} key={product.id} data-avis={product.code === PRODUCT_CODES.AVIS}>
                <img src={product.icon} />
                <ProductTitle product={product} level={2} />
                <Link to={`/${client_uuid}/abonner/${productCode}`} className={`${componentClass}__list__item__button product__button`}>{__['global__read_more']}</Link>
              </div>
            )}
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductList
