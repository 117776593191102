import React from 'react'

import './field.scss'

const Field = ({icon = null, label, defaultValue, className = '' }) => {

  return (
    <div className={`form__field field ${className}`}>
      <label className="form__field__label" >
        {icon ? icon : null}
        <span className="form__field__label__text">{label}</span>
      </label>
      <div>
        <div className={`field__field`}>{defaultValue}</div>
      </div>
    </div>
  )
}

export default Field
