import React, { useEffect, useState } from 'react'
import { Route, withRouter, Redirect, useParams } from 'react-router-dom'
import { connect } from 'react-redux';

import SiteHeader from 'components/site-header';
import { roleAccess } from 'modules/utils'
import { ssoUserProfile } from 'api'
import Menu from 'app/menu';
import __ from 'language'
import './layout.scss'

const Layout = ({ component: Component, hideMenu = false, ssoUserProfile, mustBeAuthenticated, login, location, profile, client, className, ...rest }) => {

  const { client_uuid } = useParams(true)
  const [menu, toggleMenu] = useState()
  useEffect( () => {
    ssoUserProfile()
  }, [ssoUserProfile])

  const isAuth = !!(login && login.token)

  if (mustBeAuthenticated === 'auto')
    return isAuth ? <Redirect to={`/${client_uuid}/mine-sider`} /> : <Redirect to={`/${client_uuid}/logg-inn`} />

  if (mustBeAuthenticated === true && ! (isAuth) ) {
    return <Redirect to={`/${client_uuid}/logg-inn`} />
  }
  if (mustBeAuthenticated === false && isAuth) {
    return <Redirect to={`/${client_uuid}/mine-sider`} />
  }

  const isAdmin = roleAccess(profile.role_id, 'kb_role_sub');

  const classes = [
    'layout',
    'app-sso',
    className,
    roleAccess(profile.role_id, 'kb_role_sub') ? 'app--sidebar-open' : 'app--sidebar-close',
  ].join(' ')

  return (
    <Route {...rest} render={matchProps => (
      <div className={classes}>
        <SiteHeader className="app__topbar" profile={profile.email} client={client} />
        <div className="app__main">
          {!hideMenu && isAdmin && <div className="app__main__sidebar">
            <div className="app__main__sidebar__title">{__.global__administrator}</div>
            <div className="app__main__sidebar__company">{profile.account && profile.account.company}</div>
            <Menu open={menu}/>
          </div>}
          {/* <AppClose /> */}
          <div className="app__main__wrapper">
            <div className="app__main__wrapper__content">
              <Component {...matchProps} />
              {/* <AppHeader><ClientHeader /></AppHeader> */}
            </div>
          </div>
        </div>
      </div>
    )}/>
  )
}

function mapStateToProps(state) {
  return {
    login: state.login,
    account: (state.profile && state.profile.account) || {},
    profile: (state.profile && state.profile) || {},
    client: state.client
  }
}

export default connect(
  mapStateToProps,
  { ssoUserProfile }
)(withRouter(Layout));
