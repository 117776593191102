import { combineReducers } from 'redux'
import create from './create'
import register from './register'
import org_info from './org_info'
import domain_info from './domain_info'
import add_to_account from './add_to_account'
import add_user from './add_user'
import activate_offer from './activate_offer'

export default combineReducers({
  create,
  register,
  org_info,
  domain_info,
  add_to_account,
  add_user,
  activate_offer
})

