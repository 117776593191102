import React from 'react'
import './link.scss'
import { NavLink } from 'react-router-dom'

const Link = ({children, className, to, onClick}) => (
  to ?
    <NavLink className={`link ${className}`} to={to}>{children}</NavLink>
    :
    <a onClick={onClick} className={`link ${className}`}>{children}</a>
)

export default Link
