import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Spinner from 'components/spinner';
import { ssoLoginRefresh } from 'api'

const SsoLogin = ({history, login, client, ssoLoginRefresh}) => {

  if (client && !client.pending)
  {
    if (login && login.token)
    {
      ssoLoginRefresh()
      return null;
    }
    else
    {
      history.push('logg-inn')
    }
  }

  return (
    <Spinner />
  )
}

function mapStateToProps(state) {
  return {
    login: state.login,
    client: state.client,
  }
}

export default connect(
  mapStateToProps,
  {ssoLoginRefresh}
)(withRouter(SsoLogin));
