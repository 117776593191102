import React from 'react'

import __ from "language";
import RegistrationSubscriptionForm from './registration-subscription-form';
import './registration-user.scss'


export default ({offer}) => {

  return <>
    <RegistrationSubscriptionForm offer={offer} />
  </>
}
