import React from 'react'

import __ from "language";
import './registration-offer.scss'
import RegistrationOfferPrice from './registration-offer-price'

export default ({offer, product}) => {

  return <div className="registration-offer">
    {offer.image && <div className="registration-offer__image"><img src={offer.image} /></div>}
    <div className="registration-offer__details">
      <div className="registration-offer__details__title">{offer.title}<span className="registration-offer__details__pretitle">({product.name})</span></div>
      <div className="registration-offer__details__amount"><RegistrationOfferPrice offer={offer} /></div>
    </div>
  </div>
}
