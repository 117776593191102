import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Instructions from 'components/instructions';
import Link from 'components/link'
import Input from 'components/input'
import Button from 'components/button'
import Tos from 'components/tos'
import Form from 'components/form'
import FormMsg from 'components/form-msg'
import __ from "language";
import FormError from 'components/form-error';
import { ssoValidationEmail, ssoCreateUser } from 'api'
import { SSO_USER_CREATE_SUCCESS } from 'reducers/user/create';

import './registration-user.scss'

const RegistrationUser = ({history, ssoValidationEmail, ssoCreateUser, emailValid, emailTaken, status, offer, returnUrl, location, ...rest}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [hasReadTos, setHasReadTos] = useState(false);
  const [error, setError] = useState('');

  const [formIsValid, setFormValidity] = useState(false);

  useEffect( () => {
    if (email)
    {
      ssoValidationEmail(email)
    }
  }, [email])

  const clickHandlerToLogin = () => {
    history.push('logg-inn')
  }

  const state = {
    offer,
    returnUrl
  }
  const isBasis = !offer
  const valid = emailValid && !emailTaken
  return <>
    {status === SSO_USER_CREATE_SUCCESS ?
      <>
        <div className="form__field form__field__group">
          <FormMsg msg={__.registration_user_form__success} />
        </div>
        {/* <Button onClick={clickHandlerToLogin} label={__.reset_password_continue_to_login} /> */}
      </>
    :
      <>
        <Instructions>{isBasis ? __.registration_basis_instruction : __.registration_subscription_instruction}</Instructions>
        <Form isValid={setFormValidity} className="registration">
          <Input required type="text" error={error} name="name" placeholder={__.global__name_placeholder} onChange={e => setName(e.target.value)} defaultValue={name} label={__.global__name}/>
          <Input required type="email" error={error} name="email" placeholder={__.global__email_placeholder} onChange={e => setEmail(e.target.value)} defaultValue={email} label={__.global__email}/>
          <Tos required error={error} name="tos" onChange={e => setHasReadTos(e.target.checked)} defaultValue={hasReadTos} />
          <FormError error={error} />
          { emailTaken && <FormMsg msg="registration_email_exists" />}
          <div className="form__field form__field__group form__field__group--right">
            <Link to={`logg-inn${location.search}`}>{__.registration_user__have_user_goto_login}</Link>
            <Button disabled={!valid || !hasReadTos} loading={false} onClick={() => ssoCreateUser({email, name, state })} label={__.global__register} />
          </div>
        </Form>
      </>
    }
  </>
}


function mapStateToProps(state) {
  const create = state.user && state.user.create || {}
  return {
    emailTaken: state.validation && state.validation.email && state.validation.email.taken,
    emailValid: state.validation && state.validation.email && state.validation.email.valid,
    ...create
  }
}

export default connect(
  mapStateToProps,
  { ssoValidationEmail, ssoCreateUser }
)(withRouter(RegistrationUser));

