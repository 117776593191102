import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Spinner from 'components/spinner';
import { ssoClientLogin } from 'api'

const ClientLogin = ({history, login, client, ssoClientLogin}) => {

  if (client && !client.pending)
  {
    ssoClientLogin()
  }

  return (
    <Spinner />
  )
}

function mapStateToProps(state) {
  return {
    login: state.login,
    client: state.client,
  }
}

export default connect(
  mapStateToProps,
  {ssoClientLogin}
)(withRouter(ClientLogin));
