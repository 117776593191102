import token from 'modules/token';

export const loadCookieState = () => {
  try {
    const jwt = token.get()
    if (jwt)
      return {login: {token: jwt, pending: false}}
  }
  catch ( e )
  {
    console.error(e)
  }
  return undefined
}

