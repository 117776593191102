import React from 'react';
import __ from 'language'
import { convertArrayToObject } from '../../helpers/array-helpers';
import { ssoSelectProduct } from 'api'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import { IconChecked, IconRemove } from "assets";
import { PRODUCT_CODES } from 'modules/constants'

import './products.scss';

const ProductFeatures = ({product, ssoSelectProduct, selected}) => {
  const componentClass = 'product-features';
  const classes = [
    componentClass
  ].join(' ')

  const productCode = product.code.toLowerCase()
  const productOffers = convertArrayToObject(product.offers, 'code')

  const setProduct = (offer) => {
    ssoSelectProduct({
      product: productCode,
      offer: offer ? offer : 'BASIS',
      type: selected.type
    });
  }

  if (!product.feature) return null;

  const { offers, features, action } = JSON.parse(product.feature)

  if (!offers) return null;

  return (
    <div className={classes}>
      <div className={`${componentClass}__image`}>
        <img src={product.icon} />
      </div>
      <div className={`${componentClass}__table`}>
        <div className={`${componentClass}__header-row`}>
          <div className={`${componentClass}__first-col`}></div>
          {offers.map((offer, index) => <div className={`${componentClass}__value-col`} key={index}>{offer.title}</div>)}
        </div>
        {features.map((row, index) => {
          return (
            <div key={index} className={`${componentClass}__feature-row`}>
              <div className={`${componentClass}__first-col`}>{row.text}</div>
              {row.values.map((value, index) =>
                <div key={index} className={`${componentClass}__value-col`}>
                  {value ?
                    <div className={`${componentClass}__checked`}><IconChecked /></div>
                    :
                    <div className={`${componentClass}__unchecked`}><IconRemove /></div>
                  }
                </div>
              )}
            </div>
          )
        })}
        <div className={`${componentClass}__footer-row`}>
          <div className={`${componentClass}__first-col`}></div>
          {offers.map((offer, index) =>
            {
              return (
                <div className={`${componentClass}__value-col`} key={index}>
                  <div className={`${componentClass}__value-col__price`}>
                    {offer.price ?
                      <span>{offer.price}</span>
                      :
                      <span>
                        <span>{`${productOffers[offer.code].amount}`}</span> <span>{`${__.products__price_per_year}`}</span>
                      </span>
                    }
                  </div>
                  {action && productOffers[offer.code] && productOffers[offer.code].code === PRODUCT_CODES.LDB_KOMPLETT ?
                    <a
                      className={`${componentClass}__value-col__button product__button`}
                      href={action}>
                        {offer.button}
                    </a>
                  :
                    <Link
                      to={`/${product.client_uuid}/registrere` + ( offer.code ? `?offer_uuid=${productOffers[offer.code].uuid}` : '')}
                      onClick={() => setProduct(offer.code)}
                      key={index}
                      className={`${componentClass}__value-col__button product__button`}>
                        {offer.button}
                    </Link>
                  }
                </div>
              )
            }
          )}
        </div>
      </div>
      {offers.map((offer, indexOffer) =>
        <div className={`${componentClass}__mobile`} key={indexOffer}>
          <div className={`${componentClass}__mobile__title`}>{offer.title}</div>
          <ul className="product-avis__list">
            {features.map((row, index) => {
              const show = row.values[indexOffer];
              return show ? <li key={index} className="product-avis__list__item"><IconChecked />{row.text}</li> : null
            })}
          </ul>
          <div className={`${componentClass}__mobile__price`}>
            <div className={`${componentClass}__mobile__price__text`}>
              {offer.price ?
                <span>{offer.price}</span>
                :
                <span>
                  <span>{`${productOffers[offer.code].amount}`}</span> <span>{`${__.products__price_per_year}`}</span>
                </span>
              }
            </div>
            <Link
              to={`/${product.client_uuid}/registrere` + ( offer.code ? `?offer_uuid=${productOffers[offer.code].uuid}` : '')}
              onClick={() => setProduct(offer.code)}
              className={`${componentClass}__mobile__price__button product__button`}>
                {offer.button}
            </Link>
          </div>
        </div>)
      }
    </div>

  )
}

function mapStateToProps(state) {
  return {
    // profile: state.profile,
    selected: state.product.selected
  }
}

export default connect(
  mapStateToProps,
  { ssoSelectProduct }
)(withRouter(ProductFeatures));
