import React, { useEffect }  from 'react'
import { Route, Switch, Redirect, withRouter, useParams, useRouteMatch, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import Spinner from 'components/spinner';

import { Login } from 'containers/login'
import Logout from 'containers/logout'
import { UserProfile } from 'containers/user-profile'
import { AccountMembers } from 'containers/account-members'

import { MySubscriptions } from 'containers/my-subscriptions'
import { Products } from 'containers/products'

// import { MyUsers } from 'containers/my-users'
// import { Refresh } from 'containers/refresh'
import { Registration } from 'containers/registration'
import { RegistrationUser } from 'containers/registration'
import { RegistrationAccount } from 'containers/registration'
// import { RegistrationOrg } from 'containers/registration'
import { Token } from 'containers/token'
import { ResetPassword } from 'containers/reset-password'
// import { Activation } from 'containers/activation'
// import { Playground } from 'containers/playground'
import { Tos } from 'containers/tos'
import { ClientLogin } from 'containers/client-login'
import { ClientHome } from 'containers/client-home'
import { SsoLogin } from 'containers/sso-login'
import { SsoClose } from 'containers/sso-close'
import { SsoLogout } from 'containers/sso-logout'
import { ssoClient } from 'api'

import Layout from  './layout'
import SimpleLayout from  './simple-layout'
import { OrganizationSettings } from 'containers/organization-settings';


const Client = ({client, ssoClient}) => {
  const { pathname } = useLocation();
  let { path, url } = useRouteMatch();
  let { client_uuid, ...rest} = useParams()
  var { returnUrl } = queryString.parse(window.location.search)

  useEffect(() => {
    if (client_uuid) {
      ssoClient({client_uuid, returnUrl})
    }
  }, [client_uuid])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  if (!client || client.uuid !== client_uuid) return <Spinner />

  return (
    <Switch>
      <SimpleLayout path={`${path}/tos`} component={Tos} />

      <Route path={`${path}/client_login`} component={ClientLogin} />
      <Route path={`${path}/client_home`} component={ClientHome} />
      <Route path={`${path}/sso_login`} component={SsoLogin} />
      <Route path={`${path}/sso_close`} component={SsoClose} />
      <Route path={`${path}/sso_logout`} component={SsoLogout} />

      <SimpleLayout path={`${path}/registrere`} component={Registration} />
      <SimpleLayout path={`${path}/tilbakestille-passord`} component={ResetPassword} />
      <SimpleLayout mustBeAuthenticated={false} path={`${path}/logg-inn`} component={Login} />
      <SimpleLayout path={`${path}/logg-ut`} component={Logout} />
      <SimpleLayout path={`${path}/token/:token`} component={Token} />

      <Layout className="app-products" path={`${path}/abonner/:productCode?`} component={Products} hideMenu={true} />
      <Layout className="layout-2-column" mustBeAuthenticated path={`${path}/mine-sider`} component={() => <><UserProfile /></>} />
      <Layout className="layout-2-column" mustBeAuthenticated path={`${path}/mine-medlemmer`} component={() => <><AccountMembers /></>} />
      <Layout className="layout-2-column" mustBeAuthenticated path={`${path}/organisasjonsinnstillinger`} component={() => <><OrganizationSettings /></>} />
      <Layout className="layout-2-column" mustBeAuthenticated path={`${path}/mine-abonnementene`} component={() => <MySubscriptions hideOrder={true}/>} />

      <Redirect exact path={`${path}/`} to={`${path}/sso_login`} />
    </Switch>
  )
}


function mapStateToProps(state) {
  return {
    client: state.client,
    login: state.login
  }
}

export default connect(
  mapStateToProps,
  { ssoClient }
)(withRouter(Client));
