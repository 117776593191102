import React from "react";

function IconPadlock(props) {
  return (
    <svg
      className={props.styleClass || "icon icon--padlock"}
      width={props.width}
      height="100%"
      viewBox="0 0 11 14"
      aria-labelledby="title"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Padlock</title>
      <path
        d="M9.188 4.667H8.53V3.333C8.531 1.493 7.061 0 5.25 0 3.439 0 1.969 1.493 1.969 3.333v1.334h-.657C.592 4.667 0 5.267 0 6v6.667C0 13.4.59 14 1.313 14h7.875c.721 0 1.312-.6 1.312-1.333V6c0-.733-.59-1.333-1.313-1.333zm-3.938 6c-.722 0-1.313-.6-1.313-1.334C3.938 8.6 4.529 8 5.25 8s1.313.6 1.313 1.333c0 .734-.591 1.334-1.313 1.334zm2.034-6H3.216V3.333c0-1.14.912-2.066 2.034-2.066 1.122 0 2.034.926 2.034 2.066v1.334z"
        fillRule="nonzero"
      />
    </svg>
  );
}

IconPadlock.defaultProps = {
  width: 11,
  height: 14
};

export default IconPadlock;
