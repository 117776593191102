import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Spinner from 'components/spinner';
import { ssoClientHome } from 'api'

const ClientHome = ({history, login, client, ssoClientHome}) => {

  if (client && !client.pending)
  {
    ssoClientHome()
  }

  return (
    <Spinner />
  )
}

function mapStateToProps(state) {
  return {
    login: state.login,
    client: state.client,
  }
}

export default connect(
  mapStateToProps,
  {ssoClientHome}
)(withRouter(ClientHome));
