import React, { useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { connect } from 'react-redux';


import Input from 'components/input'
import Button from 'components/button'
import Link from 'components/link'
import Form from 'components/form'
import IconEmail from "components/icons/icon-email";
import IconPadlock from "components/icons/icon-padlock";
import FormError from 'components/form-error'
import __ from "language";
import RouteHeader from 'components/route-header';
import { ssoLogin } from 'api'
import './login.scss'

const Login = ({ssoLogin, pending, error, client_uuid, location}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formIsValid, setFormValidity] = useState(false);

  return (
    <>
      <RouteHeader>{__.global__login}</RouteHeader>
      <Form className="login" isValid={setFormValidity} >
        <Input tabIndex="1" required icon={<IconEmail />} error={error} type="email" name="email" onChange={e => setEmail(e.target.value)} defaultValue={email} label={__.global__email} placeholder={__.global__email_placeholder}/>
        <Input tabIndex="2" required icon={<IconPadlock/>} error={error}  type="password" name="password" onChange={e => setPassword(e.target.value)} defaultValue={password} label={__.global__password}/>
        <Link to="tilbakestille-passord">{__.auth__forgott_my_password}</Link>
        <div className="form__field form__field__group form__field__group--right">
          <Link to={`registrere${location.search}`}>{__.auth__have_no_user_goto_registration}</Link>
          <Button disabled={pending} onClick={() => ssoLogin(email, password)} label={__.global__login} />
        </div>
      </Form>
    </>
  )
}

function mapStateToProps(state) {
  return {
    ...state.login
  }
}

export default connect(
  mapStateToProps,
  { ssoLogin }
)(withRouter(Login));
