import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import OrgInfo from  './org-info'
import { ssoOrgInfo } from 'api'

import './registration-user.scss'


const OrgQuery = ({orgnr, setOrg, ssoOrgInfo, org}) => {

  useEffect( () => {
    if (org) {
      setOrg(org)
    }
  }, [org, setOrg])

  useEffect( () => {
    ssoOrgInfo(orgnr)
  }, [orgnr, ssoOrgInfo])

  return (
    <>
      {/* <br/>
      {org && org.company && <OrgInfo org={org} /> } */}
    </>
  )
}

function mapStateToProps(state) {
  if ( ! (state && state.user) ) return {
    pending: true,
    org: null
  }
  return {
    org: state.user.org_info
  }
}

export default connect(
  mapStateToProps,
  { ssoOrgInfo }
)(OrgQuery);

