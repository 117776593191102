import React, { useEffect, useRef } from 'react'

import './dropdown.scss'

const onInput = e => {
  e.target.setCustomValidity('');
  e.target.checkValidity();
}

const validateChange = onChange => e => {
  if (!e || !e.target) return;
  onChange(e);
}

const onInvalid = e => {
  // if(inputEl.current.value === 's') {
    e.target.setCustomValidity('Enter your username!');
    // } else {
    //   inputEl.current.setCustomValidity('Usernames can only contain upper and lowercase letters. Try again!');
    // }
}

const Dropdown = ({icon = null, name, label, placeholder, defaultValue, onChange, error, className, options, type = "text", ...rest}) => {
  const inputEl = useRef(null);
  const errorMessage = error && error.variable === name ? error.message : ''

  useEffect(() => {
    inputEl.current.addEventListener('input', onInput);
    inputEl.current.addEventListener('invalid', onInvalid);

    return () => {
      inputEl.current.removeEventListener('input', onInput);
      inputEl.current.removeEventListener('invalid', onInvalid);
    };
  });

  return (
    <div className={`form__field input ${className} ${errorMessage ? 'input--error':''}`}>
      <label className="form__field__label" htmlFor={`id_${name}`}>
        {icon ? icon : null}
        <span className="form__field__label__text">{label}</span>
      </label>
      <div>
        <select id={`id_${name}`} ref={inputEl} className={`input__input`} type={type} name={name} onChange={validateChange(onChange)} placeholder={placeholder} defaultValue={defaultValue} {...rest}>
          {
            options && options.map( (option) => {
              return (
                <option key={option.id} value={option.id}>{option.label}</option>
              )
            })
          }
        </select>
      </div>
    </div>
  )
}

export default Dropdown
