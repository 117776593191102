import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom'
import Spinner from 'components/spinner';
import { ssoUserProfile, ssoProduct, ssoUserServices } from 'api'
import __ from 'language'
import { Section } from 'components/section'
import { UpdateDeliveryAddress } from 'containers/update-delivery-address'
import IconKey from "components/icons/icon-key";
import Pending from "components/pending";
import Cancelation from "components/cancelation";
import { IconAccount, IconUser, IconExternal } from "assets";
import { convertArrayToObject } from '../../helpers/array-helpers';
import { PRODUCT_CODES } from 'modules/constants'
import { routeToExternal } from 'modules/utils'
import './my-services.scss'
import moment from 'moment'

const BuyLink = ({ product, products, hasSubscriptions }) => {

  const offers = products[product.product_number] && products[product.product_number].offers || []

  return (
    <div className="my-services__products__buy_link">
      <div>
        {__.my_services__no_subscription}
        {offers.length > 0 && (
          <>
            <div>{hasSubscriptions ? __.my_services__no_subscription_renew : __.my_services__no_subscription_buy}</div>
            <ul>
              {offers.map(offer => <li key={offer.id}><Link
                        to={`/${product.client_uuid}/registrere?offer_uuid=${offer.uuid}`}>{offer.name}</Link></li>)}
            </ul>
          </>)
        }
      </div>
    </div>
  )
}
moment.locale('sv');

const SubscriptionItem = ({ subscription }) => {

  return (
    <div className="my-services__products__subscription">
      <div className="my-services__products__subscription__title">
        {subscription.title}
        {!subscription.cancleded && subscription.datestamp && <Cancelation label={`${__.global__canceled_at} ${moment(subscription.datestamp).format('LL')}`} />}
        {!subscription.datestamp && <Pending label={__.pending_subscription}/>}
      </div>
      <div className="my-services__products__subscription__action">
        {subscription.delivery_address_required && <UpdateDeliveryAddress subscription={subscription}/> }
      </div>
    </div>
  )
}

const ProductItem = ({product, products }) => {
  const history = useHistory()
  const hasSubscriptions = product.subscriptions && product.subscriptions.length
  const hasActiveSubscriptions = hasSubscriptions && product.subscriptions.filter(subscription => moment(subscription.datestamp) > moment() ).length
  const handleClick = () => {
    if (product && product.client_uuid) {
      history.push(`/${product.client_uuid}/client_home`)
    } else if (product.url) {
      window.location.href = product.url
    }
  }

  if (product.code === 'KRD') {
    // return null
  }

  return (
    <div className="my-services__products">
      <a onClick={handleClick}>
        <h3 className="my-services__products__title" >
          {product.title} <IconExternal />
        </h3>
      </a>
      <div className="my-services__products__subtitle">
        <div className="my-services__products__subscriptions">
          {product.subscriptions.map( (subscription) => <SubscriptionItem key={subscription.subscription_id} subscription={subscription} /> ) }
          {!hasActiveSubscriptions && <BuyLink product={product} products={products} hasSubscriptions={hasSubscriptions} /> }
        </div>
      </div>
    </div>
  )
}

const MyServices = ({ssoUserServices, ssoProduct, all_products, subscription, hideOrder, products}) => {

  const history = useHistory();
  useEffect( () => {
    ssoProduct()
    ssoUserServices()
  }, [])

  if (!all_products.length || !subscription ) return <Spinner />;

  const active_products = all_products.filter( p => p.subscriptions.length > 0)
  const inactive_products = all_products.filter( p => p.subscriptions.length === 0)

  return <div className="my-services">
    {active_products.map( (product) => (
      <ProductItem key={product.id} product={product} hideOrder={hideOrder} products={products} />
    ))}
    {inactive_products.map( (product) => (
      <ProductItem key={product.id} product={product} hideOrder={hideOrder} products={products} />
    ))}
    {/* <div className="my-services__moresell">
      <span>{__.my_services__copy_moresell}</span>
      <a onClick={() => history.push('abonner')}>{__.my_services__copy_moresell_link}</a>
    </div> */}

  </div>
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
    account: state.profile && state.profile.account || {},
    subscription: state.subscription ? state.subscription.list.filter(p => p.code && !PRODUCT_CODES.HIDDEN.includes(p.code)) : [],
    all_products: state.user_services ? state.user_services.list.filter(p => p.code && !PRODUCT_CODES.HIDDEN.includes(p.code)) : [],
    products: convertArrayToObject(state.product.list, 'product_number')
  }
}

export default connect(
  mapStateToProps,
  { ssoUserProfile, ssoProduct, ssoUserServices }
)(MyServices);
