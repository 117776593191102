import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Link from 'components/link'
import Input from 'components/input'
import Button from 'components/button'
import Tos from 'components/tos'
import Form from 'components/form'
import FormMsg from 'components/form-msg'
import __ from "language";
import FormError from 'components/form-error';
import { ssoCreateAccount, ssoRegistrationState } from 'api'
import { SSO_ACCOUNT_CREATE_SUCCESS } from 'reducers/account/create';

import './registration-user.scss'
import { stat } from 'fs';

// const SSO_CREATE_USER = gql`
// mutation ssoCreateUser($input: SsoCreateUserInput!) {
//   ssoCreateUser(input: $input) {
//     user {
//       id
//       email
//       roleId
//     }
//   }
// }
// `;

// const USER_EXISTS = gql`
// query GetUserByEmail($email: String!) {
// 	ssoUserExists(email: $email) {
// 	  code
// 	  message
// 	}
// }
// `;

const RegistrationAccountForm = ({ssoCreateAccount, ssoRegistrationState, user, status, skip_org = false, org = {}}) => {
  const [error, setError] = useState('');
  const [account, setAccount] = useState({})
  const [valid, setValid] = useState(false)
  const [formIsValid, setFormValidity] = useState(false);

  useEffect(() => {
    const {company, phone, orgnr} = org
    setAccount({...account, company, phone, orgnr})
  }, [org])

  useEffect(() => {
    if (status === SSO_ACCOUNT_CREATE_SUCCESS) {
      ssoRegistrationState()
    }
  }, [status])

  useEffect(() => {
    setValid(!!account.company && !!account.company && !!account.phone)
  }, [account.company, account.phone, account.orgnr])

  return <>
    {status === SSO_ACCOUNT_CREATE_SUCCESS ?
      <>
        <div className="form__field form__field__group">
          <FormMsg msg={__.registration_account_form__success} />
        </div>
      </>
    :
      <>
        <Form isValid={setFormValidity} className="registration">
            { !skip_org && <Input required type="text" error={error} name="orgnr" placeholder={__.global__org_placeholder} onChange={e => setAccount({...account, orgnr:e.target.value})} defaultValue={account.orgnr} label={__.global__org}/> }
            <Input required type="text" error={error} name="company" placeholder={__.global__company_or_name} onChange={e => setAccount({...account, company:e.target.value})} defaultValue={account.company} label={__.global__company_or_name}/>
            <Input required type="email" error={error} name="email" placeholder={__.global__email_placeholder} onChange={e => setAccount({...account, email:e.target.value})} defaultValue={account.email} label={__.global__email}/>
            <Input required type="text" error={error} name="phone" placeholder={__.global__phone_placeholder} onChange={e => setAccount({...account, phone:e.target.value})} defaultValue={account.phone} label={__.global__phone}/>
            <Input required type="email" error={error} name="invoice-email" placeholder={__.global__invoiceEmail_placeholder} onChange={e => setAccount({...account, invoiceEmail:e.target.value})} defaultValue={account.invoiceEmail} label={__.global__invoiceEmail}/>
            <div className="form__field form__field__group form__field__group--right">
              <Button disabled={!valid} onClick={() => ssoCreateAccount(account)} label={__.global__order} />
            </div>

          <FormError error={error} />
        </Form>
      </>
    }
  </>
}


function mapStateToProps(state) {
  return {
    user: state.registration && state.registration.user || {},
    status: state.account && state.account.create && state.account.create.status || ''
  }
}

export default connect(
  mapStateToProps,
  { ssoCreateAccount, ssoRegistrationState }
)(withRouter(RegistrationAccountForm));

