import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Link from 'components/link'
import Input from 'components/input'
import Button from 'components/button'
import Form from 'components/form'
import RouteHeader from 'components/route-header';
import Instructions from 'components/instructions';
import FormMsg from 'components/form-msg'
import { ssoResetPassword } from 'api'
import { SSO_RESET_PASSWORD_SUCCESS } from 'reducers/reset_password';

import __ from "language";

import './reset-password.scss'


const ResetPassword = ({history, ssoResetPassword, error, pending, status}) => {
  const [email, setEmail] = useState('')
  const [formIsValid, setFormValidity] = useState(false)

  const clickHandlerToLogin = () => {
    ssoResetPassword()
    history.push('logg-inn')
  }

  useEffect( () => {
    ssoResetPassword()
  }, [ssoResetPassword])
  return (
    <>
      <RouteHeader>{__.reset_password_header}</RouteHeader>
      <Instructions>{__.reset_password_instruction}</Instructions>
      { status ===  SSO_RESET_PASSWORD_SUCCESS ?
        <>
          <div className="form__field form__field__group">
            <FormMsg msg={__.reset_password_complete} />
          </div>
          <Button onClick={clickHandlerToLogin} label={__.reset_password_continue_to_login} />
        </>
        :
        <Form isValid={setFormValidity} className="reset-password">
          <Input required type="email" error={error} name="email" onChange={e => setEmail(e.target.value)} defaultValue={email} label="Epost"/>
          <Link className="login__link" to="logg-inn">{__.reset_password_have_login}</Link>
          <Button disabled={!formIsValid || pending} onClick={() => ssoResetPassword(email)} label="Fortsett" />
        </Form>
      }
    </>
  )
}

ResetPassword.propTypes = {
};

function mapStateToProps(state) {
  return {
    ...state.resetPassword
  }
}

export default connect(
  mapStateToProps,
  { ssoResetPassword }
)(withRouter(ResetPassword));
