import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom'
import { MessageCtx } from 'contexts'
import Input from 'components/input'
import Instructions from 'components/instructions'
import Button from 'components/button'
import Form from 'components/form'
import __ from "language";
import Spinner from 'components/spinner';
import IconEmail from "components/icons/icon-email";
import { parseError } from "modules/utils";
import { appCode } from 'app/app-title';
import { ssoCreateAccount } from 'api'

import 'components/icons/icon-checkmark_filled.png'
import './registration-org.scss'

const RegistrationOrg = ({registration, onComplete, ssoCreateAccount, account, app}) => {

  const [company, setCompany] = useState(account.company)
  const [email, setEmail] = useState(account.email)
  const [orgnr, setOrgnr] = useState(account.orgnr)
  const [phone, setPhone] = useState(account.phone)
  const [domain, setDomain] = useState(account.domain)
  const [invoiceEmail, setInvoiceEmail] = useState(account.invoiceEmail)
  const [address, setAddress] = useState(account.address)
  const [postnumber, setPostnumber] = useState(account.postnumber)
  const [city, setCity] = useState(account.city)
  const [comments, setComments] = useState(account.comments)
  const [reference, setReference] = useState(account.reference)
  const [data_policy, hasReadDataPolicy] = useState(false)
  const [error, setError] = useState('');
  const [formIsValid, setFormValidity] = useState(false);
  const [_message, setMessage] = useContext(MessageCtx)

  // const ssoCreateSubscription = useMutation(SSO_CREATE_SUBSCRIPTION, {
  //   update: (cache, { loading, data: { ssoCreateSubscription } }) => {
  //     if (!loading && ssoCreateSubscription) {
  //       setMessage(__.registration_user_form__success, 'submit')
  //     }
  //   },
  //   refetchQueries: [{
  //     query: SSO_CURRENT_SUBSCRIPTIONS_LIST
  //   }],
  //   onError: error => setError(parseError(error))
  // })

  // const ssoCreateAccount = useMutation(SSO_CREATE_ACCOUNT, {
  //   update: (cache, { loading, data: { ssoCreateAccount } }) => {
  //     if (!loading && ssoCreateAccount) {
  //       ssoCreateSubscription({ variables: {input: {app, licenses, reference}}})
  //       setMessage(__.registration_user_form__success, 'submit')
  //       onComplete()
  //     }
  //   },
  //   refetchQueries: [{
  //     query: SSU_CURRENT_USER_AUTH
  //   }],
  //   onError: error => setError(parseError(error))
  // })

  return (
    <>
      <Form isValid={setFormValidity} className="registration">
        {/*
          <Input required type="text" error={error} name="firstname" placeholder={__.global__firstname_placeholder} onChange={e => setFirstName(e.target.value)} defaultValue={firstname} label={__.global__firstname}/>
          <Input required type="text" error={error} name="lastname" placeholder={__.global__lastname_placeholder} onChange={e => setLastName(e.target.value)} defaultValue={lastname} label={__.global__lastname}/>
        */}
        <Input required type="email" error={error} name="email" placeholder={__.global__email_placeholder} onChange={e => setEmail(e.target.value)} defaultValue={email} label={__.global__email}/>
        <Input type="tel" error={error} name="phone" placeholder={__.global__phone_placeholder} onChange={e => setPhone(e.target.value)} defaultValue={phone} label={__.global__phone}/>
        <fieldset className="registration__orgfields">
          <span className="registration__orgfields__legend">{__.registration_org_your_org}</span>
          <Input required type="text" error={error} name="company" placeholder={__.global__company_placeholder} onChange={e => setCompany(e.target.value)} defaultValue={company} label={__.global__org}/>
          <Input required type="text" error={error} name="orgnr" placeholder={__.global__org_placeholder} onChange={e => setOrgnr(e.target.value)} defaultValue={orgnr} label={__.global__org}/>
          <Input required type="email" icon={<IconEmail />} error={error} name="invoice-email" placeholder={__.global__invoiceEmail_placeholder} onChange={e => setInvoiceEmail(e.target.value)} defaultValue={invoiceEmail} label={__.global__invoiceEmail}/>
          <Input type="text" error={error} name="domain" placeholder={__.global__domain_placeholder} onChange={e => setDomain(e.target.value)} defaultValue={domain} label={__.global__domain}/>
        </fieldset>
        <Input type="text" error={error} name="address" placeholder={__.global__address_placeholder} onChange={e => setAddress(e.target.value)} defaultValue={address} label={__.global__address}/>
        <Input type="text" error={error} name="postnumber" placeholder={__.global__postnumber_placeholder} onChange={e => setPostnumber(e.target.value)} defaultValue={postnumber} label={__.global__postnumber}/>
        <Input type="text" error={error} name="city" placeholder={__.global__city_placeholder} onChange={e => setCity(e.target.value)} defaultValue={city} label={__.global__city}/>
        <Input type="text" error={error} name="comments" placeholder={__.global__comments_placeholder} onChange={e => setComments(e.target.value)} defaultValue={comments} label={__.comments}/>
        <Input type="text" error={error} name="reference" placeholder={__.global__reference_placeholder} onChange={e => setReference(e.target.value)} defaultValue={reference} label={__.global__reference}/>

        <div className="form__field form__field__text">
          <strong>{__.registration_datapolicy_header}: </strong>
          {__.registration_datapolity_desc} <Link to="" >{__.auth__data_policy_message_link}</Link>
          <p className="form__field data_policy">
            <input required id="data_policy" type="checkbox" name="data_policy" onChange={e => hasReadDataPolicy(e.target.checked)} defaultValue={data_policy} />
            <label htmlFor="data_policy">{__.auth__data_policy_message}</label>
          </p>
        </div>

        <div className="form__field form__field__group">
        {/* loading={loading} */}
          <Button disabled={!formIsValid} className="button--wide"  onClick={() => ssoCreateAccount({company, email, phone, orgnr, domain, invoiceEmail, address, postnumber, city, comments, reference, app} )} label={__.registration_org_submit} />
        </div>
      </Form>
    </>
  )
}

function mapStateToProps(state) {
  return {
    account: state && state.registration && state.registration.account || {},
    app: state.client && state.client.name,
    registration: state.registration

  }
}

export default connect(
  mapStateToProps,
  { ssoCreateAccount }
)(withRouter(RegistrationOrg));




