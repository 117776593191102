import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Input from 'components/input'
import Field from 'components/field'
import Button from 'components/button'
import Form from 'components/form'
import RouteHeader from 'components/route-header';
import Spinner from 'components/spinner';
import Link from 'components/link'
import { ssoUserProfile, ssoOrganizationSettingsSave } from 'api'
import __ from 'language'
import './organization-settings.scss'

import { Modal, ModalContent, ModalActions, useModal } from 'components/modal'
import { Section } from 'components/section'
import { UpdatePassword } from 'containers/update-password'
import { UpdateUser } from 'containers/update-user'
import { UpdateInvoiceSettings } from 'containers/update-invoice-settings'
import { UpdateContactSettings } from 'containers/update-contact-settings'
import IconKey from "components/icons/icon-key";
import Pending from "components/pending";
import { IconAccount, IconUser, IconCompany } from "assets";

const OrganizationSettings = ({account, ssoUserProfile, ssoOrganizationSettingsSave }) => {

  const [email, setEmail] = useState()
  const [error, setError] = useState()
  const [activePasswordModal, setActivePasswordModal, togglePasswordModal] = useModal()

  const [name, setName] = useState()
  const [address, setAddress] = useState('')
  const [postnumber, setPostnumber] = useState('')
  const [city, setCity] = useState('')
  const [co, setCo] = useState('')

  useEffect( () => {
    ssoUserProfile()
  }, [])

  if ( !account.id ) return <Spinner />;

  return <>
    <RouteHeader>{__.organization_settings__routename}</RouteHeader>
    <Section title={__.organization_settings__general_information} subtitle={__.organization_settings__general_information_about} icon={<IconUser />} actions={<><UpdateInvoiceSettings/></>}>
      <Field defaultValue={account.invoice_email} label={__.global__email}/>
    </Section>

    <Section title={__.contact_settings__general_information} subtitle={__.contact_settings__general_information_about} icon={<IconUser />} actions={<><UpdateContactSettings/></>}>
      <Field defaultValue={`${account.firstname} ${account.lastname}`} label={__.global__name}/>
      <Field defaultValue={account.address} label={__.global__address}/>
      <Field defaultValue={account.postnumber} label={__.global__postnumber}/>
      <Field defaultValue={account.city} label={__.global__city}/>
      <Field defaultValue={account.co} label={__.global__co}/>
    </Section>
  </>
};

function mapStateToProps(state) {
  return {
    account: state.profile && state.profile.account || {}
  }
}

export default connect(
  mapStateToProps,
  { ssoUserProfile, ssoOrganizationSettingsSave }
)(withRouter(OrganizationSettings));
