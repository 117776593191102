/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "fetch|Sentry" }]*/
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react'
import { render } from 'react-dom'
import { Router } from 'react-router'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers';
import { loadCookieState} from './state';
import thunk from 'redux-thunk';
import fetch from 'unfetch'
import moment from 'moment'
import 'moment/locale/nb';

import * as Sentry from '@sentry/browser';
import App from 'app/app';
import 'index.css';
import ErrorBoundary from 'app/error-boundary'
import Notification from 'components/notification';
import { history } from './history'

moment.locale('nb');

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    maxBreadcrumbs: 50,
    debug: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'development',
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
  });
}

const cookieState = loadCookieState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  {...cookieState},
  composeEnhancers(
      applyMiddleware(thunk),
  )
)

const Root = () => (
  <Provider store={store}>
    <ErrorBoundary>
      <Router history={history}>
        <App />
        <Notification />
      </Router>
    </ErrorBoundary>
  </Provider>
);
render(<Root />, document.getElementById("root"));


