
export const SSO_FLOW = 'SSO_FLOW'
export const SSO_FLOW_SUCCESS = 'SSO_FLOW_SUCCESS'
export const SSO_FLOW_ERROR = 'SSO_FLOW_ERROR'

export default (state = {}, action) => {
  switch(action.type){
    case SSO_FLOW:
      return {
        ...state,
        ...action.payload,
    }
      default:
        return state;
  }
}