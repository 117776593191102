

export const APP_NAME = {
  ldb: 'Leverandør­databasen',
  kb: 'Kommune­barometeret',
  admin: 'Admin',
}

export const APP_CODE = {
  ldb: 'LDB',
  kb: 'KB',
  admin: 'ADMIN',
}
export const APP_USER_EVENT = {
  ldb: 'USER_CREATE_LDB',
  kb: 'USER_CREATE_KB',
  admin: 'USER_CREATE',
}
export const APP_ACCOUNT_EVENT = {
  ldb: 'ACCOUNT_CREATE_LDB',
  kb: 'ACCOUNT_CREATE_KB',
  admin: 'ACCOUNT_CREATE',
}
export const APP_URL = {
}

export const EMAIL_TEMPLATE_EVENTS = {
  USER_CREATE_LDB: 'USER_CREATE_LDB',
  USER_CREATE_KB: 'USER_CREATE_KB',
  USER_CREATE: 'USER_CREATE',
  ACCOUNT_CREATE_KRD: 'ACCOUNT_CREATE_KRD',
  ACCOUNT_CREATE_LDB: 'ACCOUNT_CREATE_LDB',
  ACCOUNT_CREATE_KB: 'ACCOUNT_CREATE_KB',
  ACCOUNT_CREATE: 'ACCOUNT_CREATE'
}
export const EMAIL_TEMPLATE_APP = {
  USER_CREATE_LDB: 'ldb',
  USER_CREATE_KB: 'kb',
  USER_CREATE: 'kb',
  ACCOUNT_CREATE_LDB: 'ldb',
  ACCOUNT_CREATE_KB: 'kb',
}
export const EMAIL_TEMPLATE_LEVEL = {
  USER_CREATE_LDB: 'user',
  USER_CREATE_KB: 'user',
  USER_CREATE: 'user',
  ACCOUNT_CREATE_LDB: 'account',
  ACCOUNT_CREATE_KB: 'account',
}

export const PRODUCT_CODES = {
  AVIS: 'KRD',
  DEFAULT: 'KRD',
  LDB_KOMPLETT: 'LDB_KOMPLETT',
  HIDDEN: ['KR_OLD']
}

export const PRODUCT_NUMBERS = {
  KB: '001',
  LDB: '002',
  KR: '003',
  KR_DIGITAL: '004'
}