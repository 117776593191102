import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Spinner from 'components/spinner';
import { ssoClose } from 'api'

const SsoClose = ({history, client, ssoClose}) => {

  if (client)
  {
    ssoClose()
    return null;
  }
  else
  {
    history.push('logg-inn')
  }

  return (
    <Spinner />
  )
}

function mapStateToProps(state) {
  return {
    login: state.login,
    client: state.client,
  }
}

export default connect(
  mapStateToProps,
  {ssoClose}
)(withRouter(SsoClose));
