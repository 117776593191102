
export const SSO_ACCOUNT_PENDING_MEMBER_UPDATE = 'SSO_ACCOUNT_PENDING_MEMBER_UPDATE'
export const SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SUCCESS = 'SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SUCCESS'
export const SSO_ACCOUNT_PENDING_MEMBER_UPDATE_ERROR = 'SSO_ACCOUNT_PENDING_MEMBER_UPDATE_ERROR'

export const SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SAVE = 'SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SAVE'
export const SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SAVE_SUCCESS = 'SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SAVE_SUCCESS'
export const SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SAVE_ERROR = 'SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SAVE_ERROR'

export const login = (email, password) => {
  return {
    type: SSO_ACCOUNT_PENDING_MEMBER_UPDATE,
    payload: {email, password}
  }
}

export default (state = { list: [],  pending: true,  error: false }, action) => {
  switch(action.type){
    case SSO_ACCOUNT_PENDING_MEMBER_UPDATE:
    return {
      ...state,
      list: [],
      pending: true,
      error: false
    }
    case SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SUCCESS:
    return {
      ...state,
      list: action.payload,
      pending: false,
      error: false
    }
    case SSO_ACCOUNT_PENDING_MEMBER_UPDATE_ERROR:
    return {
      ...state,
      ...action.payload,
      list: [],
      pending: false,
    }
    case SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SAVE:
    return {
      ...state,
      pending: true,
      error: false
    }
    case SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SAVE_SUCCESS:
    return {
      ...state,
      ...action.payload,
      pending: false,
      error: false
    }
    case SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SAVE_ERROR:
    return {
      ...state,
      ...action.payload,
      pending: false,
    }
    default:
    return state;
  }
}