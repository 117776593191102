
export const SSO_USER_CREATE = 'SSO_USER_CREATE'
export const SSO_USER_CREATE_SUCCESS = 'SSO_USER_CREATE_SUCCESS'
export const SSO_USER_CREATE_ERROR = 'SSO_USER_CREATE_ERROR'

export default (state = {pending: false}, action) => {
  switch(action.type){
    case SSO_USER_CREATE:
      return {
        ...state,
        status: action.type,
        pending: true,
        error: false
      }
      case SSO_USER_CREATE_SUCCESS:
        return {
          ...state,
          status: action.type,
          ...action.payload,
          pending: false,
          error: false
        }
        case SSO_USER_CREATE_ERROR:
          return {
            ...state,
            status: action.type,
            ...action.payload,
            pending: false,
          }
      default:
        return state;
  }
}