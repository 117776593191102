import React, { useEffect, useRef } from 'react'
import './form.scss'


const Form = ({children, className, isValid, handleSubmit, ...rest }) => {
  const formRef = useRef(null);

  const onSubmit = e => {
    e.preventDefault();
    if (handleSubmit) handleSubmit(formRef.current)
  }

  useEffect(() => {
    formRef.current.addEventListener('submit', onSubmit);
    formRef.current.addEventListener('input', () => isValid && isValid(formRef.current.checkValidity()));

    return () => {
      formRef.current.removeEventListener('submit', onSubmit);
      formRef.current.removeEventListener('input', () => isValid && isValid(formRef.current.checkValidity()));
    };
  });

  return (
    <form ref={formRef} className={`form ${className}`} onSubmit={e => e.preventDefault()} {...rest}>{children}</form>
  )
};

export default Form
