import React, { useEffect } from 'react'
import { Route, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import SiteHeader from 'components/site-header';
import { ssoUserProfile } from 'api'

const Layout = ({ component: Component, ssoUserProfile, profile, client, mustBeAuthenticated, login, flow, location, ...rest }) => {

  const isAuth = !!(login && login.token)

  useEffect( () => {
    if (isAuth) {
      ssoUserProfile()
    }
  }, [isAuth])

  if (mustBeAuthenticated === 'auto')
  if (isAuth)
    return <Redirect to="mine-sider" />
  else
    return <Redirect to="logg-inn" />

  if (mustBeAuthenticated === true && ! (isAuth) )
    return <Redirect to="logg-inn" />
  if (mustBeAuthenticated === false && isAuth)
    return <Redirect to="mine-sider" />

  const classes = [
    'layout',
    'simple-layout',
    'app-sso',
    location.pathname.startsWith('/abonner') ? 'app-products' : ''
  ].join(' ')

  return (
    <Route {...rest} render={matchProps => (
      <div className={classes}>
        <SiteHeader className="app__topbar" profile={profile.email} client={client} />
        <div className="app__main">
          <div className="app__main__wrapper">
            <div className="app__main__wrapper__content">
              <Component {...matchProps} />
            </div>
          </div>
        </div>
      </div>
    )}/>
  )
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    login: state.login,
    client: state.client,
  }
}

export default connect(
  mapStateToProps, {
    ssoUserProfile
  }
)(withRouter(Layout));
