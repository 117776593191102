import React from "react";

function IconEmail(props) {
  return (
    <svg
      className="icon icon--email"
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero">
        <path d="M9.375 0H.625C.25 0 0 .232 0 .58v.811L5 4l5-2.55V.58C10 .232 9.75 0 9.375 0z" />
        <path d="M4.688 5.71L0 3v5.355C0 8.742.25 9 .625 9h8.75C9.75 9 10 8.742 10 8.355V3L5.312 5.71a.755.755 0 0 1-.625 0z" />
      </g>
    </svg>
  );
}

IconEmail.defaultProps = {
  width: 10,
  height: 9
};

export default IconEmail;
