import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import Spinner from 'components/spinner';
import { ssoLogout } from 'api'

const SsoLogout = ({history, client, login, ssoLogout}) => {

  if (client && client.uuid) {

    var { returnUrl } = queryString.parse(window.location.search)

    if (login && login.token)
    {
      ssoLogout()
      return null;
    }
    else if (returnUrl)
    {
      window.location.href = returnUrl
    }
    else if (client.logout_url)
    {
      window.location.href = client.logout_url
    }
    else if (client.home_url)
    {
      window.location.href = client.home_url
    }
    else
    {
      history.push('logg-inn')
    }
  }

  return (
    <Spinner />
  )
}

function mapStateToProps(state) {
  return {
    login: state.login,
    client: state.client
  }
}

export default connect(
  mapStateToProps,
  {ssoLogout}
)(withRouter(SsoLogout));
