import React from "react";

function IconKummunalRapport(props) {
  return (
    <svg version="1.1" id="Lager_1" x="21px" y="184px" viewBox="0 0 184 21" enableBackground="new 0 0 184 21" >
      <g>
        <path fill="#FFFFFF" d="M182.2,17.1c0.7,0,1.3-0.1,1.8-0.3v-0.7c-0.3,0.1-1,0.2-1.4,0.2c-1.1,0-1.5-0.5-1.5-1.6V5.3h3V4.5h-3V1.8
          h-1.1v2.7h-1.6v0.8h1.6v9.6C179.9,16.4,180.7,17.1,182.2,17.1 M171.3,17h4v-0.8h-1.5V7.5c1-1.2,2.5-2,4.1-2v-1
          c-1.8,0-3.2,0.9-4.1,2.2h0V4.5h-2.5v0.8h1.4v10.9h-1.3V17z M166,16.3c-2.2,0-3.9-1.3-3.9-5v-1.1c0-3.7,1.7-5,3.9-5
          c2.3,0,3.9,1.3,3.9,5v1.1C169.9,15,168.2,16.3,166,16.3 M166,17.2c2.9,0,5.1-1.7,5.1-6v-0.8c0-4.3-2.2-6-5.1-6
          c-2.9,0-5.1,1.7-5.1,6v0.8C160.9,15.5,163,17.2,166,17.2 M154.8,16.3c-1.1,0-2.3-0.2-3.2-0.7V6.7c1-0.9,2.2-1.4,3.5-1.4
          c2.3,0,3.8,0.9,3.8,4.7v1.2C158.9,15,157,16.3,154.8,16.3 M149.2,20.6h4v-0.7h-1.6v-3.5c0.9,0.6,2.2,0.7,3.4,0.7
          c2.9,0,5.1-1.7,5.1-6.1v-1c0-4.6-2-5.7-4.6-5.7c-1.6,0-2.9,0.7-3.8,1.5h0V4.5h-2.4v0.8h1.3v14.6h-1.3V20.6z M143.4,16.3
          c-1.1,0-2.3-0.2-3.2-0.7V6.7c1-0.9,2.2-1.4,3.5-1.4c2.3,0,3.8,0.9,3.8,4.7v1.2C147.4,15,145.6,16.3,143.4,16.3 M137.7,20.6h4v-0.7
          h-1.6v-3.5c0.9,0.6,2.2,0.7,3.4,0.7c2.9,0,5.1-1.7,5.1-6.1v-1c0-4.6-2-5.7-4.6-5.7c-1.6,0-2.9,0.7-3.8,1.5h0V4.5h-2.4v0.8h1.3v14.6
          h-1.3V20.6z M132.2,16.4c-1.7,0-2.6-0.9-2.6-2.4v-0.8c0-1.6,1.1-2.3,2.8-2.4l3.1-0.3v4.4C134.8,15.8,133.3,16.4,132.2,16.4
          M132,17.1c1.3,0,2.9-0.6,3.6-1.6h0c0,1,0.5,1.5,1.6,1.5c0.3,0,0.7-0.1,0.9-0.1v-0.7h-0.5c-0.7,0-1-0.3-1-1.2V7.5
          c0-2.3-1.4-3.1-3.6-3.1c-1.5,0-2.8,0.4-3.7,0.8v0.8c0.8-0.3,2.2-0.7,3.5-0.7c1.7,0,2.7,0.5,2.7,2.4v2.1l-3.5,0.4
          c-2,0.3-3.6,1.1-3.6,3.2V14C128.5,16,129.6,17.1,132,17.1 M118.9,8.9V1.7h2.7c2.7,0,3.8,0.9,3.8,3.2v0.7c0,2.3-1.3,3.3-3.7,3.3
          H118.9z M116.2,17h4.3v-0.8h-1.6V9.8h3.2l4.2,7.2h2v-0.8h-1.2l-3.9-6.7c2.4-0.4,3.3-2,3.3-4.1V4.9c0-2.6-1.4-3.9-4.8-3.9h-5.6v0.8
          h1.5v14.6h-1.5V17z"/>
        <path fill="#FFFFFF" d="M106.9,17h6.5v-3h-0.8V0h-5.6v3h0.8v11h-0.8V17z M100.2,14.5c-0.5,0-1-0.3-1-1v-0.9c0-0.9,0.5-1.2,1.2-1.3
          l0.6-0.1v2.8C100.9,14.4,100.5,14.5,100.2,14.5 M98.1,17.2c1.6,0,2.9-0.5,3.4-1.7h0.1c0.2,1,1,1.6,2.9,1.6c1.2,0,1.7-0.1,2.2-0.3
          V14h-0.3c-0.3,0-0.5-0.1-0.5-0.5V7.7c0-2.9-1.7-4-4.9-4c-2.3,0-4,0.4-5.3,0.9v3.2c0.9-0.1,1.8-0.2,3-0.2c1.8,0,2.4,0.3,2.4,1v0.6
          l-2.5,0.4c-2.1,0.3-3.9,1.1-3.9,3.6v0.7C94.6,16.3,95.9,17.2,98.1,17.2 M81.1,17h6.1v-3h-0.5V7.7c0.4-0.2,0.8-0.3,1.2-0.3
          c0.6,0,0.8,0.2,0.8,1V14h-0.5v3h6.1v-3h-0.8V6.7c0-2.1-1.4-3-3.2-3c-1.8,0-2.9,0.7-3.7,1.6h-0.1V4h-5.5v3.2H82V14h-0.8V17z
          M71.8,17.3c1.7,0,2.6-0.6,3.4-1.4h0.1V17h5.5v-3H80V4h-5.6v3.2h0.8v6.1c-0.3,0.2-0.7,0.4-1.1,0.4c-0.7,0-0.9-0.3-0.9-1.1V4h-5.6
          v3.2h0.8v6.6C68.3,16,69.2,17.3,71.8,17.3 M47.7,17h6.1v-3h-0.4V7.7c0.4-0.2,0.7-0.3,1.1-0.3c0.6,0,0.8,0.2,0.8,1V14h-0.5v3h5.7v-3
          H60V7.7c0.3-0.2,0.7-0.3,1.1-0.3c0.6,0,0.8,0.2,0.8,1V14h-0.4v3h6v-3h-0.8V6.7c0-2.1-1.4-3-3.1-3c-1.9,0-2.8,0.7-3.7,1.7h-0.1
          c-0.4-1.1-1.6-1.7-2.9-1.7c-1.9,0-2.8,0.7-3.6,1.6h-0.1V4h-5.5v3.2h0.8V14h-0.8V17z M27.6,17h6.1v-3h-0.4V7.7
          c0.4-0.2,0.7-0.3,1.1-0.3c0.6,0,0.8,0.2,0.8,1V14h-0.5v3h5.7v-3h-0.4V7.7c0.3-0.2,0.7-0.3,1.1-0.3c0.6,0,0.8,0.2,0.8,1V14h-0.4v3h6
          v-3h-0.8V6.7c0-2.1-1.4-3-3.1-3c-1.9,0-2.8,0.7-3.7,1.7h-0.1c-0.4-1.1-1.6-1.7-2.9-1.7c-1.9,0-2.8,0.7-3.6,1.6h-0.1V4h-5.5v3.2h0.8
          V14h-0.8V17z M21.2,14c-0.9,0-1.3-0.6-1.3-2.3V9.5c0-1.7,0.4-2.3,1.3-2.3c0.9,0,1.3,0.6,1.3,2.3v2.2C22.5,13.4,22.1,14,21.2,14
          M21.2,17.3c3.7,0,6.2-2.2,6.2-6.4v-0.8c0-4.2-2.5-6.4-6.2-6.4c-3.7,0-6.2,2.2-6.2,6.4v0.8C15,15.1,17.5,17.3,21.2,17.3 M10,17h5
          v-3h-0.8l-3.4-6l3.4-4.1H15v-3H8.7v3h0.9L6.2,7.8V3.9h0.9v-3H0v3h0.9V14H0v3h7.1v-3H6.2v-2.4l0.6-0.5L10,17z"/>
      </g>
    </svg>    
  );
}

IconKummunalRapport.defaultProps = {
  width: 16,
  height: 16
};

export default IconKummunalRapport;
