
export const SSO_PRODUCT = 'SSO_PRODUCT'
export const SSO_PRODUCT_SUCCESS = 'SSO_PRODUCT_SUCCESS'
export const SSO_PRODUCT_ERROR = 'SSO_PRODUCT_ERROR'

export const SSO_PRODUCT_SAVE = 'SSO_PRODUCT_SAVE'
export const SSO_PRODUCT_SAVE_SUCCESS = 'SSO_PRODUCT_SAVE_SUCCESS'
export const SSO_PRODUCT_SAVE_ERROR = 'SSO_PRODUCT_SAVE_ERROR'

export const SSO_PRODUCT_SELECT = 'SSO_PRODUCT_SELECT'

export const login = (email, password) => {
  return {
    type: SSO_PRODUCT,
    payload: {email, password}
  }
}

export default (state = { list: [], pending: true, error: false, selected: {product: null, offer: null, type: 'papir'}}, action) => {
  switch(action.type){
    case SSO_PRODUCT:
    return {
      ...state,
      list: [],
      pending: true,
      error: false
    }
    case SSO_PRODUCT_SUCCESS:
    return {
      ...state,
      list: action.payload,
      pending: false,
      error: false
    }
    case SSO_PRODUCT_ERROR:
    return {
      ...state,
      ...action.payload,
      list: [],
      pending: false,
    }
    case SSO_PRODUCT_SAVE:
    return {
      ...state,
      pending: true,
      error: false
    }
    case SSO_PRODUCT_SAVE_SUCCESS:
    return {
      ...state,
      ...action.payload,
      pending: false,
      error: false
    }
    case SSO_PRODUCT_SAVE_ERROR:
    return {
      ...state,
      ...action.payload,
      pending: false,
    }
    case SSO_PRODUCT_SELECT:
      return {
        ...state,
        selected: {
          ...action.payload
        }
      }
    default:
    return state;
  }
}