import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import RouteHeader from 'components/route-header';
import Button from 'components/button'
import Spinner from 'components/spinner';
import Checkblock from 'components/checkblock'
import OrgInfo from 'containers/registration/org-info';
import {APP_URL, APP_NAME} from 'modules/constants'
import { parseError } from "modules/utils";
import Input from 'components/input';
import { ssoSubscription, ssoProduct, ssoCreateSubscription, ssoUpdateSubscription } from 'api'
import __ from 'language'
import './my-subscriptions.scss'


const ProductItem = ({product, subscriptions, hideOrder}) =>
<div className="product">
<h3>{product.title}</h3>
<div>
{product.offers.map( (offer) => (
  <OfferItem key={offer.id} offer={offer} hideOrder={hideOrder}  subscriptions={subscriptions.filter( s => s.offer_id === offer.id)} />
  ))}
  </div>
  </div>


  const OfferItem = withRouter(({history, offer, subscriptions, hideOrder}) => {


    const [checked, setChecked] = useState( subscriptions.length === 0 )
    const active = subscriptions.length > 0
    const cls = `offer ${active ? 'offer--active':'offer--inactive'}`

    if (!offer.product || !offer.product.code) {
      return null
    }

    const productCode = offer.product.url
    const productTitle = offer.product.title
    const productclient_uuid = offer.product.client_uuid

    const handleOfferClick = (offer) => {
      const url = `/${productclient_uuid}/sso_login?returnUrl=${encodeURIComponent(offer.product.url+'/#')}`
      window.location.href = url
    }

    const productName = active ? offer.name : __.my_subscriptions_no_license
    return <div className={cls}>
    <div className="offer__description">
    <Checkblock check={active} title={`${productName}`}>
    {active && !hideOrder && <span className="offer__action" onClick={() => { setChecked(!checked)}}>{__.my_subscriptions_open_invoice}</span> }
    </Checkblock>
    <Button className="" onClick={() => handleOfferClick(offer)} label={`${__.my_subscriptions_licenses_to_app} ${productTitle}`} />
    </div>
    <div className="offer__form">
    {checked && <>
      <SubscriptionItem offer={offer} subscription={subscriptions ? subscriptions[0] : null} hideOrder={hideOrder}  onCreateSubscription={ () => { setChecked(false)} }/>
      </>}
      </div>
      </div>
    })

    const SubscriptionItemComponent = ({offer, subscription, onCreateSubscription, hideOrder, ssoUpdateSubscription, ssoCreateSubscription, ...props}) => {
      const [reference, setReference] = useState( subscription ? subscription.reference : '' )
      const [licenses, setLicenses] = useState( subscription ? subscription.licenses : 1 )
      const [error, setError] = useState('');
      const [app, setApp] = useState( offer ? offer.product.code : '' )

      if (hideOrder)
      {
        return null;
      }
      return <div className="subscription">
      <Input name="reference" onChange={e => setReference(e.target.value)} label={__.global__reference} defaultValue={reference}/>
      {subscription && subscription.id ?
        <Button onClick={() => { ssoUpdateSubscription( {id:subscription.id, reference, licenses} ) } } label={__.global__save} />
        :
        <>
        <Input name="licenses" onChange={e => setLicenses( parseInt(e.target.value))} type="number" label={__.global__licenses} defaultValue={licenses}/>
        <Button onClick={() => { ssoCreateSubscription({app, reference, licenses}) } } label={__.my_subscriptions_buy} />
        </>
      }
      </div>
    }

    const SubscriptionItem = connect(null, {ssoUpdateSubscription, ssoCreateSubscription})(SubscriptionItemComponent)

    const is_active_product = (subscriptions) => (product) =>
    {
      return subscriptions.filter( s => product.offers.filter( o => o.id === s.offer_id).length > 0 ).length > 0
    }

    const MySubscriptions = ({org, ssoSubscription, ssoProduct, product, subscription, hideInactive = false, hideOrder = false}) => {

      useEffect( () => {
        ssoProduct()
        ssoSubscription()
      }, [])

      if (!product.length || !subscription ) return <Spinner />;

      const visible_products = hideInactive ? product.filter(is_active_product(subscription)) : product

      const cls = `my-subscriptions my-subscriptions${hideInactive ? '--hide-inactive':''}`
      return <div className={cls}>

      { hideOrder && <RouteHeader>{__.my_subscriptions_header}</RouteHeader>}
      {org && <OrgInfo org={org} /> }

      <div>
      {visible_products.map( (product) => (
        <ProductItem key={product.id} product={product} subscriptions={subscription} hideOrder={hideOrder} />
        ))}
        </div>

        </div>
      };


      function mapStateToProps(state) {
        return {
          subscription: state.subscription ? state.subscription.list : [],
          product: state.product ? state.product.list : []
        }
      }

      export default connect(
        mapStateToProps,
        { ssoSubscription, ssoProduct }
        )(withRouter(MySubscriptions));
