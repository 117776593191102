import React from 'react'
import './cancelation.scss'


const Cancelation = ({label}) => {

  return <div className={`cancelation`}>
    {label}
  </div>
}
export default Cancelation
