
export const SSO_SUBSCRIPTION = 'SSO_SUBSCRIPTION'
export const SSO_SUBSCRIPTION_SUCCESS = 'SSO_SUBSCRIPTION_SUCCESS'
export const SSO_SUBSCRIPTION_ERROR = 'SSO_SUBSCRIPTION_ERROR'

export const SSO_SUBSCRIPTION_SAVE = 'SSO_SUBSCRIPTION_SAVE'
export const SSO_SUBSCRIPTION_SAVE_SUCCESS = 'SSO_SUBSCRIPTION_SAVE_SUCCESS'
export const SSO_SUBSCRIPTION_SAVE_ERROR = 'SSO_SUBSCRIPTION_SAVE_ERROR'

export const login = (email, password) => {
  return {
    type: SSO_SUBSCRIPTION,
    payload: {email, password}
  }
}

export default (state = { list: [],  pending: true,  error: false }, action) => {
  switch(action.type){
    case SSO_SUBSCRIPTION:
    return {
      ...state,
      list: [],
      pending: true,
      error: false
    }
    case SSO_SUBSCRIPTION_SUCCESS:
    return {
      ...state,
      list: action.payload,
      pending: false,
      error: false
    }
    case SSO_SUBSCRIPTION_ERROR:
    return {
      ...state,
      ...action.payload,
      list: [],
      pending: false,
    }
    case SSO_SUBSCRIPTION_SAVE:
    return {
      ...state,
      pending: true,
      error: false
    }
    case SSO_SUBSCRIPTION_SAVE_SUCCESS:
    return {
      ...state,
      ...action.payload,
      pending: false,
      error: false
    }
    case SSO_SUBSCRIPTION_SAVE_ERROR:
    return {
      ...state,
      ...action.payload,
      pending: false,
    }
    default:
    return state;
  }
}