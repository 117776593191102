import React from "react";

function IconCross(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.styleClass || "icon icon--cross"}
      version="1.1"
      width={props.width}
      height={"100%"}
      viewBox={`0 0 14 14`}
      preserveAspectRatio="xMidYMid meet"
      aria-labelledby="title"
    >
      <title>Ta bort</title>
      <path
        d="M 3.325,13.2125 7,9.45 10.675,13.2125 13.2125,10.675 9.45,7 13.2125,3.325 10.675,0.7875 7,4.55 3.325,0.7875 0.7875,3.325 4.55,7 0.7875,10.675 Z"
        fill="inherit"
      />
    </svg>
  );
}
IconCross.defaultProps = {
  width: 14,
  height: 14
};

export default IconCross;
