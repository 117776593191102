import React from "react";

function IconPerson(props) {
  return (
    <svg
      className={props.styleClass || "icon icon--person"}
      width={props.width}
      height="100%"
      viewBox="0 0 14 14"
      preserveAspectRatio="xMidYMid meet"
      aria-labelledby="title"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Person</title>
      <g fillRule="nonzero" fill="inherit">
        <circle cx="7" cy="3.5" r="3.5" />
        <path d="M14 11.55a1.74 1.74 0 0 0-1.018-1.59C11.847 9.445 9.793 8.75 7 8.75s-4.847.696-5.982 1.21A1.74 1.74 0 0 0 0 11.55V14h14v-2.45z" />
      </g>
    </svg>
  );
}

IconPerson.defaultProps = {
  width: 14,
  height: 14
};

export default IconPerson;
