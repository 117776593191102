import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Input from 'components/input'
import Field from 'components/field'
import Button from 'components/button'
import Form from 'components/form'
import RouteHeader from 'components/route-header';
import Spinner from 'components/spinner';
import Link from 'components/link'
import { ssoAccountMembers, ssoAccountPendingMemberUpdate, ssoAccountPendingMembers } from 'api'
import __ from 'language'
import './account-members.scss'

import { Modal, ModalContent, ModalActions, useModal } from 'components/modal'
import { Section } from 'components/section'
import { UpdatePassword } from 'containers/update-password'
import { UpdateUser } from 'containers/update-user'
import IconKey from "components/icons/icon-key";
import Pending from "components/pending";
import { IconAccount, IconUsers, IconCompany, IconPlus, IconRemove, IconCheck, IconPencil } from "assets";
import { MyServices } from 'containers/my-services'
import { AddUser } from 'containers/add-user'
import { AddUserSubscription } from 'containers/add-user-subscription'
import { UpdateUserSubscription } from 'containers/update-user-subscription'

const formSerialize = formElement => {
  const values = {};
  const inputs = formElement.elements;

  for (let i = 0; i < inputs.length; i++) {
    values[inputs[i].name] = inputs[i].value;
  }
  return values;
}


const UpdateUserMembership = ({user, activate = true, onClick}) => {
  const icon = activate ? <IconCheck /> : <IconRemove />
  return (
    <Button theme="white" icon={icon} onClick={onClick} />
    )
}

const AddUserMembership = () => {
  const icon = <IconCheck />
  // <Button label={__.account_members_section_existing_members_invite} theme="link" icon={<IconPlus />} />
  return (
    <AddUser label={__.account_members_section_existing_members_invite} theme="link" icon={<IconPlus />}/>
  )
}

const AccountMemberUserSubscription = ({subscription}) => {
  return (
    <div className="account-members__user__subscription">
      <h4>{subscription.title}</h4>
      <UpdateUserSubscription subscription={subscription} />
    </div>
  )
}

const AccountPendingMemberUserSubscription = ({label, checked, toggle}) => {
  const classes = `account-pending-members__user__subscription ${checked ? '':'account-pending-members__user__subscription--reject'}}`
  return (
    <div className={classes}>
      <input type="checkbox" onClick={() => { toggle(!checked) } } defaultChecked={checked}/>
      <h4>{label}</h4>
    </div>
  )
}

const AccountMemberUser = ({user}) => {
  return (
    <div className="account-members__user">
       <h3 className="account-members__user__title" >{user.name}</h3>
       <p>{user.email}</p>
       <>
        {user.subscriptions.map( subscription => <AccountMemberUserSubscription key={subscription.subscription_id} subscription={subscription} />)}
       </>
       <div>
        <AddUserSubscription user={user} />
      </div>
    </div>
  )
}


const AccountPendingMemberUser = connect(null, { ssoAccountPendingMemberUpdate,  })(

  ({user, ssoAccountPendingMemberUpdate }) => {

    const [list, setList] = useState(user.subscriptions.map( s => ({active: true, label: s.title, subscription_id: s.subscription_id})))

    const toggle = (subscription) => (active) => {
      const item = list.filter( s => s.subscription_id === subscription.subscription_id)
      if (item) {
        item[0].active = active
      }
      setList(list)
    }

    return (
      <Form className="account-pending-members__user" >
        <div className="account-pending-members__user__content" >
          <h3 className="account-pending-members__user__content__title" >
            {user.name}
          </h3>
          <div className="account-pending-members__user__content__subtitle">
            {user.email}
          </div>
          <div className="account-pending-members__user__content__services">
            {
              list.map(
                subscription =>
                  <AccountPendingMemberUserSubscription
                  key={subscription.subscription_id}
                  label={subscription.label}
                  checked={subscription.active}
                  toggle={toggle(subscription) }/>
              )
            }
          </div>
        </div>
        <div className="account-pending-members__user__actions">
          <UpdateUserMembership user={user} activate={true} onClick={() => ssoAccountPendingMemberUpdate({user, list, approve_user: true}) }/>
          <UpdateUserMembership user={user} activate={false} onClick={() => ssoAccountPendingMemberUpdate({user, list, approve_user: false}) }/>
        </div>
      </Form>
    )
  }
)





const AccountMembers = ({profile, ssoAccountMembers, ssoAccountPendingMembers, account, account_members, account_pending_members}) => {

  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [error, setError] = useState()
  const [activePasswordModal, setActivePasswordModal, togglePasswordModal] = useModal()
  useEffect( () => {
    ssoAccountMembers()
    ssoAccountPendingMembers()
  }, [])

  if ( !profile ) return <Spinner />;


  return <>
    <RouteHeader>{__.account_members_header}</RouteHeader>

    {account_pending_members && account_pending_members.length > 0 &&
      <Section theme="info" title={__.account_members_section_new_members} subtitle={__.account_members_section_new_members_subtitle} icon={<IconPlus />} >
        { account_pending_members &&
          account_pending_members.map( user => <AccountPendingMemberUser key={user.id} user={user}/>)}
      </Section>
    }
      <Section title={__.account_members_section_existing_members} subtitle={<AddUserMembership />} theme='members' icon={<IconUsers />} >
      { account_members &&
        account_members.map( (account_member) => <AccountMemberUser key={account_member.id} user={account_member} />)}
    </Section>

  </>
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
    account: state.profile && state.profile.account || {},
    account_members: state.account_members && state.account_members.list || [],
    account_pending_members: state.account_pending_members && state.account_pending_members.list || [],
  }
}

export default connect(
  mapStateToProps,
  { ssoAccountMembers, ssoAccountPendingMembers, ssoAccountPendingMemberUpdate }
)(withRouter(AccountMembers));
