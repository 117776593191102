import React from 'react'
import Link from 'components/link'
import { Modal, ModalContent, ModalActions, useModal } from 'components/modal'
import Button from 'components/button'
import __ from 'language'
import './tos.scss'
import { Tos as TosPage }  from 'containers/tos'
const Tos = ({name, onChange, ...rest }) => {

  const [active, setActive, toggle] = useModal()

  return <p className="form__field tos">
    <input id="tos" type="checkbox" name={name} onChange={onChange} {...rest} />
    <label htmlFor="tos">{__.global__tos_message}
      <Link className="tos__link" onClick={toggle}>{__.global__tos_message_link}</Link>
      <Modal active={active} toggle={toggle} title={__.global__tos_title}>
        <ModalContent>
          <TosPage />
        </ModalContent>
        <ModalActions>
          <Button theme="secondary" onClick={toggle} label={__.global__close} />
        </ModalActions>
      </Modal>

    </label>
  </p>
}

export default Tos