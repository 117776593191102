

export const routeToExternal = (product) => {
  window.location.href = product.url
}

const role_value = {
  'kb_role_adm' : 1,
  'kb_role_sub' : 2,
  'kb_role_usr' : 3
}

export const roleAccess = (user_role_id, limit_role_id) => {
  const user_level = role_value[user_role_id]
  const limit_level = role_value[limit_role_id]
  const result = user_level > 0 && limit_level > 0 && user_level <= limit_level

  return result
}

export const parseJwt = (token) => {
  if (!token) {
    return {};
  }
  var base64Url = token.split('.')[1];
  if (!base64Url) {
    return {};
  }
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export const uuid = (a) => {return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,uuid)}

export const isUuid = (uuid) => uuid && /[a-zA-Z0-9-]+/.test(uuid)

export const parseError = error =>
  (error && error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].message)
    ? JSON.parse(error.graphQLErrors[0].message)
    : error;

export const visiolink_access = jwt => 
    jwt && jwt.user_id > 0 && jwt.grants && jwt.grants.indexOf('KRD') > -1
    || jwt && jwt.user_id > 0 && jwt.grants && jwt.grants.indexOf('KRE') > -1 
