import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Button from 'components/button'
import { ssoLogout } from 'api'
import __ from "language";

const Logout = ({history, ssoLogout}) => {

  useEffect( () => {
    ssoLogout()
  },[])

  const clickHandlerToLogin = () => {
    history.push('logg-inn')
  }

  return (
    <div className="logout">
      <p>{__.global__logout_success}</p>
      <Button onClick={clickHandlerToLogin} label={__.reset_password_continue_to_login} />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    ...state.login
  }
}

export default connect(
  mapStateToProps,
  { ssoLogout }
)(withRouter(Logout));
