import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter, useParams, Redirect } from 'react-router-dom'

import Spinner from 'components/spinner';
import Product from './product';
import ProductList from './product-list';
import { ssoUserProfile, ssoUserProfileSave, ssoProduct } from 'api'
import { PRODUCT_CODES, PRODUCT_NUMBERS } from 'modules/constants'
import __ from 'language'
import './products.scss'


const Products = ({profile, product, ssoUserProfile, ssoUserProfileSave, ssoProduct}) => {

  let { productCode, client_uuid } = useParams();

  const defaultProduct = PRODUCT_CODES.AVIS.toLowerCase();

  productCode = productCode ? productCode : defaultProduct;

  useEffect( () => {
    ssoProduct()
    ssoUserProfile()
  }, [ssoProduct, ssoUserProfile])

  if ( product.list.length === 0 ) return <Spinner />;

  const sortedProducts = product.list.filter(p => p.offers.length > 0 && p.code && !PRODUCT_CODES.HIDDEN.includes(p.code)).sort((a, b) => a.id - b.id);
  const currentProducts = sortedProducts.filter(p => p.code.toLowerCase().startsWith(productCode.toLowerCase()));
  const otherProducts = sortedProducts.filter(p => !p.code.toLowerCase().startsWith(productCode.toLowerCase()) && p.product_number !== PRODUCT_NUMBERS.KR_DIGITAL);

  if(currentProducts.length == 0) {
    return <Redirect to={`/${client_uuid}/abonner`}/>;
  }

  return <>
    <Product products={currentProducts} />
    <ProductList products={otherProducts} title={__.products_list_header}></ProductList>
  </>
};

function mapStateToProps(state) {
  return {
    profile: state.profile,
    product: state.product
  }
}

export default connect(
  mapStateToProps,
  { ssoUserProfile, ssoUserProfileSave, ssoProduct }
)(withRouter(Products));
