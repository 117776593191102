
export const SSO_RESET_PASSWORD = 'SSO_RESET_PASSWORD'
export const SSO_RESET_PASSWORD_SUCCESS = 'SSO_RESET_PASSWORD_SUCCESS'
export const SSO_RESET_PASSWORD_ERROR = 'SSO_RESET_PASSWORD_ERROR'
export const SSO_RESET_PASSWORD_CLEAR = 'SSO_RESET_PASSWORD_CLEAR'

export const login = (email) => {
  return {
    type: SSO_RESET_PASSWORD,
    payload: {email}
  }
}

export default (state = {}, action) => {
  switch(action.type){
    case SSO_RESET_PASSWORD:
      return {
        ...state,
        status: action.type,
        pending: true,
        error: false
      }
      case SSO_RESET_PASSWORD_CLEAR:
        return {
          ...state,
          status: action.type,
          pending: false,
          error: false
        }
        case SSO_RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          ...action.payload,
          status: action.type,
          pending: false,
          error: false
        }
        case SSO_RESET_PASSWORD_ERROR:
          return {
            ...state,
            ...action.payload,
            status: action.type,
            pending: false,
          }
      default:
        return state;
  }
}