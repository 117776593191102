import React from 'react'
import { ERRORS } from 'constant'
import './feedback.scss'


const Feedback = ({message, type = 'error'}) => { 

  const text = ERRORS[message] ? ERRORS[message] : message;
  const cls = `feedback--${type}`
  return <div className={`feedback ${cls}`}>{text}</div>
}
export default Feedback
