
export const SSO_ORG_INFO = 'SSO_ORG_INFO'
export const SSO_ORG_INFO_SUCCESS = 'SSO_ORG_INFO_SUCCESS'
export const SSO_ORG_INFO_ERROR = 'SSO_ORG_INFO_ERROR'

export default (state = {pending: false}, action) => {
  switch(action.type){
    case SSO_ORG_INFO:
      return {
        ...state,
        status: action.type,
        pending: true,
        error: false
      }
      case SSO_ORG_INFO_SUCCESS:
        return {
          ...state,
          status: action.type,
          ...action.payload,
          pending: false,
          error: false
        }
        case SSO_ORG_INFO_ERROR:
          return {
            ...state,
            status: action.type,
            ...action.payload,
            pending: false,
          }
      default:
        return state;
  }
}