import React, { useState } from 'react'
import { connect } from 'react-redux';
import { withRouter, useParams, useHistory } from 'react-router-dom'

import Form from 'components/form'
import Input from 'components/input'
import Button from 'components/button'
import FormError from 'components/form-error'
import __ from "language";
import './token.scss'
import RouteHeader from 'components/route-header';
import Instructions from 'components/instructions';
import FormMsg from 'components/form-msg'
import { EMAIL_TEMPLATE_APP} from 'modules/constants'
import { ssoUpdatePasswordWithToken, ssoLoginByToken, ssoClientLogin } from 'api'
import { SSO_UPDATE_PASSWORD_SUCCESS } from 'reducers/update_password';

const pwdIsGood = (pwd) => pwd.length >= 6
// && /[A-Z]/.test(pwd) && /[a-z]/.test(pwd) && /\d/.test(pwd);

const Token = ({status, ssoLoginByToken, ssoUpdatePasswordWithToken, ssoClientLogin}) => {

  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [error, setError] = useState('')
  const [formIsValid, setFormValidity] = useState(false)
  const { client_uuid, token} = useParams()
  const history = useHistory()

  if (!token) return null;

  return (
    <>
      <RouteHeader>{__.registration_set_password_title}</RouteHeader>
      <Instructions>{__.registration_set_password_instruction}</Instructions>
      {status === SSO_UPDATE_PASSWORD_SUCCESS ?
        <>
          <div className="form__field form__field__group">
            <FormMsg msg={__.registration_set_password_success} />
          </div>
          <Button onClick={ () => ssoLoginByToken(token, password) } label={__.token_continue_to_login} />
        </>
      :
        <>
          <Form className="registration">
            <Input required type="password" error={error} name="password" onChange={e => setPassword(e.target.value)} defaultValue={password} label={__.auth__password}/>
            <Input required type="password" error={error} name="password2" onChange={e => setPassword2(e.target.value)} defaultValue={password2} label={__.auth__password_confirm}/>
            <FormError error={error} />
            <Button disabled={!(pwdIsGood(password) && password === password2)} onClick={() => ssoUpdatePasswordWithToken({password, token} )} label={__.auth__password_submit} />
          </Form>
        </>
      }
    </>
  )
}

Token.propTypes = {
};

function mapStateToProps(state) {
  return {
    ...state.updatePassword
  }
}

export default connect(
  mapStateToProps,
  { ssoUpdatePasswordWithToken, ssoClientLogin, ssoLoginByToken }
)(Token);
