import React, { useContext, useState, useEffect } from 'react'
import { MessageCtx } from 'contexts'
import classnames from 'classnames'
import Feedback from 'components/feedback'
import './instructions.scss'

const Instructions = ({children, emph = false}) => {
  
  const [message] = useContext(MessageCtx)
  const [show, setShow] = useState(true)
  useEffect(() => {
    setShow(true)
  }, [message]);

  const cls = classnames({
    'instructions': true,
    'instructions--emph': emph,
  })

  const content = show && message[0] ? <Feedback {...message[0]}  /> : children

  return (
    <div className={cls} dangerouslySetInnerHTML={{ __html: content }} />
  )
}
export default Instructions
