import React from 'react'
import './infobox.scss'
import __ from 'language';

export default ({children}) => {
  return (
    <div className={`infobox`}>
      {children}
    </div>
  )
}
