import token_store from 'modules/token';
import jwtlib from 'jsonwebtoken'
import buildUrl from 'build-url'
import { history } from './history'
import queryString from 'query-string'
import { visiolink_access } from './modules/utils'

import { SSO_LOGOUT } from './reducers/logout'
import { SSO_LOGIN, SSO_LOGIN_SUCCESS, SSO_LOGIN_ERROR } from './reducers/login'
import { SSO_LOGIN_REFRESH, SSO_LOGIN_REFRESH_SUCCESS, SSO_LOGIN_REFRESH_ERROR } from './reducers/refresh'
import { SSO_USER_CREATE, SSO_USER_CREATE_SUCCESS, SSO_USER_CREATE_ERROR } from './reducers/user/create'
import { SSO_ADD_USER, SSO_ADD_USER_SUCCESS, SSO_ADD_USER_ERROR } from './reducers/user/add_user'
import { SSO_REGISTER_USER, SSO_REGISTER_USER_SUCCESS, SSO_REGISTER_USER_ERROR } from './reducers/user/register'
import { SSO_REGISTRATION_STATE, SSO_REGISTRATION_STATE_SUCCESS, SSO_REGISTRATION_STATE_ERROR } from './reducers/registration'

import { SSO_ADD_USER_TO_ACCOUNT_CREATE, SSO_ADD_USER_TO_ACCOUNT_CREATE_SUCCESS, SSO_ADD_USER_TO_ACCOUNT_CREATE_ERROR } from './reducers/user/add_to_account'

import { SSO_ACCOUNT_CREATE, SSO_ACCOUNT_CREATE_SUCCESS, SSO_ACCOUNT_CREATE_ERROR } from './reducers/account/create'
import { SSO_ACCOUNT_SUBSCRIPTION_CREATE, SSO_ACCOUNT_SUBSCRIPTION_CREATE_SUCCESS, SSO_ACCOUNT_SUBSCRIPTION_CREATE_ERROR } from './reducers/account/subscription'

import { SSO_ORG_INFO, SSO_ORG_INFO_SUCCESS, SSO_ORG_INFO_ERROR } from './reducers/user/org_info'
import { SSO_DOMAIN_INFO, SSO_DOMAIN_INFO_SUCCESS, SSO_DOMAIN_INFO_ERROR } from './reducers/user/domain_info'
import { SSO_PROFILE, SSO_PROFILE_SUCCESS, SSO_PROFILE_ERROR } from './reducers/profile'
import { SSO_CLIENT, SSO_CLIENT_SUCCESS, SSO_CLIENT_ERROR } from './reducers/client'
import { SSO_SUBSCRIPTION, SSO_SUBSCRIPTION_SUCCESS, SSO_SUBSCRIPTION_ERROR } from './reducers/subscription'
import { SSO_USER_SERVICES, SSO_USER_SERVICES_SUCCESS, SSO_USER_SERVICES_ERROR } from './reducers/user_services'
import { SSO_PRODUCT, SSO_PRODUCT_SUCCESS, SSO_PRODUCT_ERROR, SSO_PRODUCT_SELECT } from './reducers/product'
import { SSO_PROFILE_SAVE, SSO_PROFILE_SAVE_SUCCESS, SSO_PROFILE_SAVE_ERROR } from './reducers/profile'
import { SSO_RESET_PASSWORD, SSO_RESET_PASSWORD_CLEAR, SSO_RESET_PASSWORD_SUCCESS, SSO_RESET_PASSWORD_ERROR } from './reducers/reset_password';
import { SSO_UPDATE_PASSWORD, SSO_UPDATE_PASSWORD_CLEAR, SSO_UPDATE_PASSWORD_SUCCESS, SSO_UPDATE_PASSWORD_ERROR } from './reducers/update_password';
import { SSO_ACCOUNT_MEMBERS, SSO_ACCOUNT_MEMBERS_SUCCESS, SSO_ACCOUNT_MEMBERS_ERROR } from './reducers/account_members';
import { SSO_ACCOUNT_PENDING_MEMBERS, SSO_ACCOUNT_PENDING_MEMBERS_SUCCESS, SSO_ACCOUNT_PENDING_MEMBERS_ERROR } from './reducers/account_pending_members';
import { SSO_ACCOUNT_PENDING_MEMBER_UPDATE, SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SUCCESS, SSO_ACCOUNT_PENDING_MEMBER_UPDATE_ERROR } from './reducers/account_pending_member_update';
import { SSO_ACCOUNT_SERVICES, SSO_ACCOUNT_SERVICES_SUCCESS, SSO_ACCOUNT_SERVICES_ERROR } from './reducers/account_services';
import { SSO_ACTIVATE_OFFER, SSO_ACTIVATE_OFFER_SUCCESS, SSO_ACTIVATE_OFFER_ERROR } from './reducers/user/activate_offer';
import { SSO_NOTIFICATION, SSO_NOTIFICATION_DISMISS } from './reducers/notification';



import { SSO_VALIDATION_EMAIL, SSO_VALIDATION_EMAIL_RESPONSE } from './reducers/validation/email'

let tsc = 0

const ts = () => {
  tsc ++
  return (Date.now()) * 1000 + tsc;
}
export const ssoNotification = (message) => {
  return dispatch => {
    dispatch({type: SSO_NOTIFICATION, payload: message});
  }
}

export const ssoNotificationDismiss = () => {
  return dispatch => {
    dispatch({type: SSO_NOTIFICATION_DISMISS});
  }
}


export const ssoLogout = () => {

  return (dispatch) => {
    token_store.remove()
    dispatch({type: SSO_LOGOUT});
  }
}

export const ssoUserProfile = () => {
  return dispatch => {
    dispatch({type: SSO_PROFILE});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_user_profile?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers(),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
            throw Error(res.error);
          }
          dispatch({type: SSO_PROFILE_SUCCESS, payload: res});
      })
      .catch(error => {
        if (error && error.message && error.message.match(/api_error/)) {
          dispatch(ssoLogout())
        }
        dispatch({type: SSO_PROFILE_ERROR, payload: {error: error.message}});
      })
  }
}
export const ssoUserProfileSave = (name) => {
  return dispatch => {
    dispatch({type: SSO_PROFILE_SAVE});
    const body = JSON.stringify({name})
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_profile?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_PROFILE_SAVE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_PROFILE_SAVE_ERROR, payload: {error: error.message}});
        dispatch(ssoNotification(error.message))
      })
  }
}


export const ssoOrganizationSettings = () => {
  return dispatch => {
    dispatch({type: SSO_PROFILE});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_user_profile?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers(),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_PROFILE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_PROFILE_ERROR, payload: {error: error.message}});
      })
  }
}
export const ssoOrganizationSettingsSave = (name) => {
  return dispatch => {
    dispatch({type: SSO_PROFILE_SAVE});
    const body = JSON.stringify({name})
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_profile?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_PROFILE_SAVE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_PROFILE_SAVE_ERROR, payload: {error: error.message}});
      })
  }
}


export const ssoContactSettings = () => {
  return dispatch => {
    dispatch({type: SSO_PROFILE});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_user_profile?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers(),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_PROFILE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_PROFILE_ERROR, payload: {error: error.message}});
      })
  }
}
export const ssoContactSettingsSave = (name) => {
  return dispatch => {
    dispatch({type: SSO_PROFILE_SAVE});
    const body = JSON.stringify({name})
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_profile?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_PROFILE_SAVE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_PROFILE_SAVE_ERROR, payload: {error: error.message}});
      })
  }
}



export const ssoAccountServices = () => {
  return dispatch => {
    dispatch({type: SSO_ACCOUNT_SERVICES});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_account_services?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers(),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_ACCOUNT_SERVICES_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ACCOUNT_SERVICES_ERROR, payload: {error: error.message}});
      })
  }
}


export const ssoAccountPendingMembers = () => {
  return dispatch => {
    dispatch({type: SSO_ACCOUNT_PENDING_MEMBERS});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_account_pending_members?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers(),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_ACCOUNT_PENDING_MEMBERS_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ACCOUNT_PENDING_MEMBERS_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoAccountMembers = () => {
  return dispatch => {
    dispatch({type: SSO_ACCOUNT_MEMBERS});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_account_members?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers(),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_ACCOUNT_MEMBERS_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ACCOUNT_MEMBERS_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoAccountPendingMemberUpdate = ({user, list, approve_user}) => {
  const approve_list = list.filter((value) => value.active).map( v => v.subscription_id)
  const reject_list = list.filter((value) => !value.active).map( v => v.subscription_id)

  return dispatch => {
    dispatch({type: SSO_ACCOUNT_PENDING_MEMBER_UPDATE});


    const body = JSON.stringify({user_id: user.id, approve_list, reject_list, approve_user})
    fetch(`${process.env.REACT_APP_API_URL}/sso/account_pending_member_update?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_ACCOUNT_PENDING_MEMBER_UPDATE_SUCCESS, payload: res});
          dispatch(ssoAccountPendingMembers())
          dispatch(ssoAccountMembers())
        })
      .catch(error => {
        dispatch({type: SSO_ACCOUNT_PENDING_MEMBER_UPDATE_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoAccountMembersSave = (name) => {
  return dispatch => {
    dispatch({type: SSO_PROFILE_SAVE});
    const body = JSON.stringify({name})
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_profile?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_PROFILE_SAVE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_PROFILE_SAVE_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoClient = ({client_uuid, returnUrl}) => {
  return dispatch => {
    dispatch({type: SSO_CLIENT});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_client/${client_uuid}?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers(),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_CLIENT_SUCCESS, payload: {...res.client, return_url: returnUrl}});
      })
      .catch(error => {
        dispatch({type: SSO_CLIENT_ERROR, payload: {error: error.message}});
      })
  }
}


export const ssoOrgInfo = (orgnr) => {
  const param = encodeURIComponent(orgnr)
  return dispatch => {
    dispatch({type: SSO_ORG_INFO});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_org_info/${param}?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers({'Content-Type':'application/json'}),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_ORG_INFO_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ORG_INFO_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoRegistrationState = (args = null) => {
  var offer_uuid, client_uuid, cart_uuid
  return (dispatch, getState) => {
    if (!args) {
      const state = getState()
      offer_uuid = state.registration.offer.uuid
      client_uuid = state.registration.client_uuid
      cart_uuid = state.registration.cart_uuid
    } else {
      offer_uuid = args.offer_uuid
      client_uuid = args.client_uuid
      cart_uuid = args.cart_uuid
    }

    dispatch({type: SSO_REGISTRATION_STATE});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_registration_state?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({offer_uuid, client_uuid, cart_uuid})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_REGISTRATION_STATE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_REGISTRATION_STATE_ERROR, payload: {error: error.message}});
        dispatch(ssoNotification(error.message))
      })
  }
}


export const ssoDomainInfo = ({orgnr}) => {
  return dispatch => {
    dispatch({type: SSO_DOMAIN_INFO});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_domain?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({orgnr})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_DOMAIN_INFO_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_DOMAIN_INFO_ERROR, payload: {error: error.message}});
      })
  }
}


export const ssoValidationEmail = (value) => {
  return dispatch => {
    dispatch({type: SSO_VALIDATION_EMAIL});
    fetch(`${process.env.REACT_APP_API_URL}/sso/validate_email?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({value: value})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_VALIDATION_EMAIL_RESPONSE, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_VALIDATION_EMAIL_RESPONSE, payload: {error: error.message}});
      })
  }
}

export const ssoRegisterUser = ({email, password, name, client_uuid}) => {
  return dispatch => {
    dispatch({type: SSO_REGISTER_USER});
    fetch(`${process.env.REACT_APP_API_URL}/sso/register_user?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({email, password, name, client_uuid})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_REGISTER_USER_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_REGISTER_USER_ERROR, payload: {error: error.message}});
      })
  }
}


export const ssoUpdateUserSubscription = ({user_id, subscription_id}) => {
  return dispatch => {
    dispatch({type: SSO_ADD_USER});
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_user_subscription?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({user_id, subscription_id})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch(ssoAccountPendingMembers())
          dispatch(ssoAccountMembers())
          dispatch({type: SSO_ADD_USER_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ADD_USER_ERROR, payload: {error: error.message}});
      })
  }
}
export const ssoAddUserSubscription = ({user_id, service_id}) => {
  return dispatch => {
    dispatch({type: SSO_ADD_USER});
    fetch(`${process.env.REACT_APP_API_URL}/sso/add_user_subscription?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({user_id, service_id})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch(ssoAccountPendingMembers())
          dispatch(ssoAccountMembers())
          dispatch({type: SSO_ADD_USER_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ADD_USER_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoActivateOffer = ({offer_id, name, address, postnumber, city, reference, activation_code, email, email_active, address_active, invoice_separate_address, invoice_name, invoice_address, invoice_postnumber, invoice_city}) => {
  return (dispatch, getState) => {

    const state = getState()
    const { cart_uuid } = state.registration

    dispatch({type: SSO_ACTIVATE_OFFER});
    fetch(`${process.env.REACT_APP_API_URL}/sso/activate_offer?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({offer_id, name, address, postnumber, city, reference, activation_code, email, email_active, address_active, cart_uuid, invoice_separate_address, invoice_name, invoice_address, invoice_postnumber, invoice_city})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch(ssoRegistrationState())
          dispatch(ssoAccountPendingMembers())
          dispatch(ssoAccountMembers())
          dispatch({type: SSO_ACTIVATE_OFFER_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ACTIVATE_OFFER_ERROR, payload: {error: error.message}});
        dispatch(ssoNotification(error.message))
      })
  }
}

export const ssoAddUser = ({email, name}) => {
  return dispatch => {
    dispatch({type: SSO_ADD_USER});
    fetch(`${process.env.REACT_APP_API_URL}/sso/add_user?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({email, name})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch(ssoAccountPendingMembers())
          dispatch({type: SSO_ADD_USER_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ADD_USER_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoCreateUser = ({email, name, state}) => {
  return (dispatch, getState) => {
    dispatch({type: SSO_USER_CREATE});
    const {client_uuid} = getState().client

    fetch(`${process.env.REACT_APP_API_URL}/sso/create_user?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({email, name, state, client_uuid})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_USER_CREATE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_USER_CREATE_ERROR, payload: {error: error.message}});
        dispatch(ssoNotification(error.message))
      })
  }
}

export const ssoCreateAccount = ({company, email, phone, orgnr, domain, invoiceEmail, address, postnumber, city, comments, licenses, reference, app}) => {
  return dispatch => {
    dispatch({type: SSO_ACCOUNT_CREATE});
    fetch(`${process.env.REACT_APP_API_URL}/sso/create_account?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({company, email, phone, orgnr, domain, invoiceEmail, address, postnumber, city, comments, licenses, reference, app})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_ACCOUNT_CREATE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ACCOUNT_CREATE_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoCreateSubscription = ({licenses, reference, app}) => {
  return dispatch => {
    dispatch({type: SSO_ACCOUNT_SUBSCRIPTION_CREATE});
    fetch(`${process.env.REACT_APP_API_URL}/sso/create_subscription?ts=${ts()}`, {
      method: 'post',
          headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({licenses, reference, app})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_ACCOUNT_SUBSCRIPTION_CREATE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ACCOUNT_SUBSCRIPTION_CREATE_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoUpdateSubscription = ({id, licenses, reference}) => {
  return dispatch => {
    dispatch({type: SSO_ACCOUNT_SUBSCRIPTION_CREATE});
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_subscription?ts=${ts()}`, {
      method: 'post',
          headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({id, licenses, reference})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_ACCOUNT_SUBSCRIPTION_CREATE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ACCOUNT_SUBSCRIPTION_CREATE_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoAddUserToAccount = ({orgnr}) => {
  return dispatch => {
    dispatch({type: SSO_ADD_USER_TO_ACCOUNT_CREATE});
    fetch(`${process.env.REACT_APP_API_URL}/sso/add_user_to_account?ts=${ts()}`, {
      method: 'post',
          headers: token_store.headers({'Content-Type':'application/json'}),
      body: JSON.stringify({orgnr})
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_ADD_USER_TO_ACCOUNT_CREATE_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_ADD_USER_TO_ACCOUNT_CREATE_ERROR, payload: {error: error.message}});
      })
  }
}



export const ssoUserServices = () => {
  return dispatch => {
    dispatch({type: SSO_USER_SERVICES});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_user_services?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers(),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_USER_SERVICES_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_USER_SERVICES_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoSubscription = () => {
  return dispatch => {
    dispatch({type: SSO_SUBSCRIPTION});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_subscription?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers(),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_SUBSCRIPTION_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_SUBSCRIPTION_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoProduct = () => {
  return dispatch => {
    dispatch({type: SSO_PRODUCT});
    fetch(`${process.env.REACT_APP_API_URL}/sso/get_product?ts=${ts()}`, {
      method: 'get',
      headers: token_store.headers(),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_PRODUCT_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_PRODUCT_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoLoginByToken = (auth_token, password) => {
  return (dispatch, getState) => {
    const {client_uuid} = getState().client
    const body = JSON.stringify({token: auth_token, password, client_uuid})
    dispatch({type: SSO_LOGIN});
    fetch(`${process.env.REACT_APP_API_URL}/sso/login_user_by_token?ts=${ts()}`, {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          token_store.set(res.token)

          dispatch({type: SSO_LOGIN_SUCCESS, payload: res});
          if (res.state && res.state.returnUrl) {
            window.location.href = res.state.returnUrl
          } else {
            dispatch(ssoClientLogin(res))
          }
        })
      .catch(error => {
        dispatch({type: SSO_LOGIN_ERROR, payload: {error: error.message}});
      })
  }
}

export const ssoLogin = (email, password) => {
  return (dispatch, getState) => {
    const {client_uuid} = getState().client
    const body = JSON.stringify({email, password, client_uuid})
    dispatch({type: SSO_LOGIN});
    fetch(`${process.env.REACT_APP_API_URL}/sso/login_user?ts=${ts()}`, {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw Error(res.error);
        }
        token_store.set(res.token)
        //dispatch({type: SSO_LOGIN_SUCCESS, payload: res});
        dispatch(ssoLoginRedirect(res))

      })
      .catch(error => {
        dispatch({type: SSO_LOGIN_ERROR, payload: {error: error.message}})
        dispatch(ssoNotification(error.message))
    })
  }
}

export const ssoLoginRefresh = () => {
  return (dispatch, getState) => {
    const {client_uuid} = getState().client
    const body = JSON.stringify({client_uuid})
    dispatch({type: SSO_LOGIN_REFRESH});
    fetch(`${process.env.REACT_APP_API_URL}/sso/refresh_token?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
              throw Error(res.error);
          }
          token_store.set(res.token)

          dispatch({type: SSO_LOGIN_REFRESH_SUCCESS, payload: res});
          dispatch(ssoLoginRedirect(res))
      })
      .catch(error => {
        dispatch({type: SSO_LOGIN_REFRESH_ERROR, payload: {error: error.message}});
      })
  }
}


export const ssoClientLogin = () => {
  return (dispatch, getState) => {

    var { login_url, client_uuid } = getState().client
    if (login_url) {
      window.location.href = login_url
      return
    }
    if (client_uuid) {
      history.push(`/${client_uuid}/sso_login`)
      return
    }
    history.push(`/`)
  }
}

export const ssoClientHome = () => {
  return (dispatch, getState) => {

    var { home_url, client_uuid } = getState().client
    if (home_url) {
      window.location.href = home_url
      return
    }
    if (client_uuid) {
      history.push(`/${client_uuid}/mine-sider`)
      return
    }
    history.push(`/`)
  }
}


export const ssoLoginRedirect = (res) => {
  const { token } = res
  return (dispatch, getState) => {

    const { search } = window.location
    const { return_url } = queryString.parse(search)    

    try {
      var { method, callback_url, uuid } = getState().client      
      const jwt = jwtlib.decode(token)
      
      const url = return_url || callback_url
      if (uuid === 'visiolink')
      {
        const access = visiolink_access(jwt)
        console.log("ssoLoginRedirect", access)
        if (!access) {
          console.log("ssoLoginRedirect redirect")
          return window.location.href = `/${uuid}/abonner`
        }
      }
      if (jwt && url)
      {
        switch (method) {
          case 'implicit':
            if (callback_url.indexOf("#") > 0) {
              return window.location.href = buildUrl(url, { path: token })
            }
            return window.location.href = buildUrl(url, { hash: token })
            break
          default:
            if (callback_url.indexOf("?") > 0) {
              const [base, query = ''] = url.split('?')
              const queryParams = queryString.parse(query)
              // Visiolink fix. Name code param as token
              return window.location.href = buildUrl(base, { queryParams: {...queryParams, token: jwt.code} })
            }
            return window.location.href = buildUrl(url, { path: jwt.code })
          break
        }
      }
    }
    catch (e)
    {
      console.error(e)
    }
    // If no returnUrl..
    dispatch({type: SSO_LOGIN_SUCCESS, payload: res});
    history.push('mine-sider')
  }
}


export const ssoClose = () => {
  return (dispatch, getState) => {

    try {
      const { returnUrl, client_uuid } = getState().client
      if (returnUrl)
      {
        window.location.href = returnUrl
        return
      }
      history.push(`/${client_uuid}/mine-sider`)
    }
    catch (e)
    {
      console.error(e)
    }
    history.push(`/mine-sider`)
    // If no returnUrl..
  }
}


export const ssoResetPassword = (email) => {
  if (!email)
  {
    return dispatch => {
      dispatch({type: SSO_RESET_PASSWORD_CLEAR});
    }
  }
  return (dispatch, getState) => {
    const {client_uuid} = getState().client
    const body = JSON.stringify({email, client_uuid})
    dispatch({type: SSO_RESET_PASSWORD});
    fetch(`${process.env.REACT_APP_API_URL}/sso/user_token/reset_password?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
          if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_RESET_PASSWORD_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_RESET_PASSWORD_ERROR, payload: {error: error.message}});
        dispatch(ssoNotification(error.message))
      })
  }
}


export const ssoUpdatePassword = ({password}) => {

  return dispatch => {
    const body = JSON.stringify({password})

    dispatch({type: SSO_UPDATE_PASSWORD});
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_password?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
          if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_UPDATE_PASSWORD_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_UPDATE_PASSWORD_ERROR, payload: {error: error.message}});
        dispatch(ssoNotification(error.message))
      })
  }
}
export const ssoUpdatePasswordWithToken = ({password, token}) => {

  return dispatch => {
    const body = JSON.stringify({password, token})

    dispatch({type: SSO_UPDATE_PASSWORD});
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_password_with_token?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
          if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_UPDATE_PASSWORD_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_UPDATE_PASSWORD_ERROR, payload: {error: error.message}});
        dispatch(ssoNotification(error.message))
      })
  }
}

export const ssoUpdateInvoiceSettings = ({invoice_email}) => {

  return dispatch => {
    const body = JSON.stringify({invoice_email})

    dispatch({type: SSO_UPDATE_PASSWORD});
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_invoice_settings?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
          if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_UPDATE_PASSWORD_SUCCESS, payload: res});
          dispatch(ssoUserProfile());
      })
      .catch(error => {
        dispatch({type: SSO_UPDATE_PASSWORD_ERROR, payload: {error: error.message}});
        dispatch(ssoNotification(error.message))
      })
  }
}


export const ssoUpdateContactSettings = ({address, postnumber, city, firstname, lastname, co}) => {

  return dispatch => {
    const body = JSON.stringify({address, postnumber, city, firstname, lastname, co})

    dispatch({type: SSO_UPDATE_PASSWORD});
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_contact_settings?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
          if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_UPDATE_PASSWORD_SUCCESS, payload: res});
          dispatch(ssoUserProfile());
      })
      .catch(error => {
        dispatch({type: SSO_UPDATE_PASSWORD_ERROR, payload: {error: error.message}});
        dispatch(ssoNotification(error.message))
      })
  }
}


export const ssoUpdateDeliveryAddress = ({subscription_id, address_id, address, postnumber, city, name, co}) => {

  return dispatch => {
    const body = JSON.stringify({subscription_id, address_id, address, postnumber, city, name, co})

    dispatch({type: SSO_UPDATE_PASSWORD});
    fetch(`${process.env.REACT_APP_API_URL}/sso/update_delivery_address?ts=${ts()}`, {
      method: 'post',
      headers: token_store.headers({'Content-Type':'application/json'}),
      body
    })
      .then(res => res.json())
      .then(res => {
          if (res.error) {
              throw Error(res.error);
          }
          dispatch({type: SSO_UPDATE_PASSWORD_SUCCESS, payload: res});
      })
      .catch(error => {
        dispatch({type: SSO_UPDATE_PASSWORD_ERROR, payload: {error: error.message}});
        dispatch(ssoNotification(error.message))
      })
  }
}



export const ssoSelectProduct = (data) => {
  return (dispatch) => {
    dispatch({type: SSO_PRODUCT_SELECT, payload: data});
  }
}