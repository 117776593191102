import React from "react";
import './spinner.scss'

type Props = {
  narrow: ?Boolean
};
class Spinner extends React.Component<Props> {
  render() {
    let cls = "spinner";
    if (this.props.narrow) {
      cls += " spinner--narrow";
    }
    return (
      <div className={cls}>
        <div className="spinner__inner">
          <svg viewBox="0 0 64 64">
            <g>
              <circle cx="16" cy="32" strokeWidth="0" r="3.7336">
                <animate
                  attributeName="fill-opacity"
                  dur="750ms"
                  values=".5;.6;.8;1;.8;.6;.5;.5"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="r"
                  dur="750ms"
                  values="3;3;4;5;6;5;4;3"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx="32" cy="32" strokeWidth="0" r="3">
                <animate
                  attributeName="fill-opacity"
                  dur="750ms"
                  values=".5;.5;.6;.8;1;.8;.6;.5"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="r"
                  dur="750ms"
                  values="4;3;3;4;5;6;5;4"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx="48" cy="32" strokeWidth="0" r="3.2664">
                <animate
                  attributeName="fill-opacity"
                  dur="750ms"
                  values=".6;.5;.5;.6;.8;1;.8;.6"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="r"
                  dur="750ms"
                  values="5;4;3;3;4;5;6;5"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default Spinner;
