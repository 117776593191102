
export const SSO_NOTIFICATION = 'SSO_NOTIFICATION'
export const SSO_NOTIFICATION_DISMISS = 'SSO_NOTIFICATION_DISMISS'

export default (state = {open: false, message: ''}, action) => {
  switch(action.type){
    case SSO_NOTIFICATION:
      return {
        ...state,
        open: true,
        message: action.payload || '',
      }
      case SSO_NOTIFICATION_DISMISS:
        return {
          ...state,
          open: false,
          message: ''
          }
      default:
        return state;
  }
}