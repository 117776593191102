
export const SSO_CLIENT = 'SSO_CLIENT'
export const SSO_CLIENT_SUCCESS = 'SSO_CLIENT_SUCCESS'
export const SSO_CLIENT_ERROR = 'SSO_CLIENT_ERROR'
export const SSO_CLIENT_DEFAULT = 'SSO_CLIENT_DEFAULT'


export default (state = {pending: true}, action) => {
  switch(action.type){
    case SSO_CLIENT:
    return {
      ...state,
      pending: true,
      error: false
    }
    case SSO_CLIENT_SUCCESS:
    return {
      ...state,
      ...action.payload,
      pending: false,
      error: false
    }
    case SSO_CLIENT_ERROR:
    return {
      ...state,
      ...action.payload,
      pending: false,
    }
    case SSO_CLIENT_DEFAULT:
    return {
      pending: false,
      name: 'Kommunal Rapport'
    }
    default:
    return state;
  }
}