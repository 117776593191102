
export const SSO_LOGOUT = 'SSO_LOGOUT'
export const SSO_LOGOUT_SUCCESS = 'SSO_LOGOUT_SUCCESS'
export const SSO_LOGOUT_ERROR = 'SSO_LOGOUT_ERROR'


export default (state = {}, action) => {
  switch(action.type){
    case SSO_LOGOUT:
      return {
      }
      default:
        return state;
  }
}