import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Form from 'components/form'
import Dropdown from 'components/dropdown'
import Button from 'components/button'
import FormError from 'components/form-error'
import __ from "language";
import { ssoAddUserSubscription, ssoAccountServices } from 'api'
import { Modal, ModalContent, ModalActions, useModal } from 'components/modal'
import { IconPlus } from "assets";
import './add-user-subscription.scss'

const AddUserSubscription = ({account_services, ssoAccountServices, ssoAddUserSubscription, user}) => {

  const [service_id, set_service_id] = useState()
  const [user_id, set_user_id] = useState()
  const [activeUserModal, setActiveUserModal, toggleUserModal] = useModal()
  const [error, setError] = useState('')

  useEffect( () => {
    if (activeUserModal) {
      ssoAccountServices()
    }
  }, [activeUserModal, ssoAccountServices])

  useEffect( () => {
    set_user_id(user.id)
  }, [user])

  const handleUpdate = (user_id, service_id) => {
    ssoAddUserSubscription({user_id, service_id})
    toggleUserModal()
  }

  const options = account_services.map( s => ({id: s.service_id, label: s.title}))

  return (
    <>
      <Button onClick={toggleUserModal} label={__.account_members_section_existing_members_add_subscription} size="small" theme="white" icon={<IconPlus/>} />

      <Form>
        <Modal active={activeUserModal} toggle={toggleUserModal} title={__.admin__add_user_subscription}>
          <ModalContent>
            <Dropdown name="service_id" onChange={e => set_service_id(e.target.value)} defaultValue={service_id} label={__.global__service} options={options}/>
            <FormError error={error} />
          </ModalContent>
          <ModalActions>
            <Button theme="secondary" onClick={toggleUserModal} label={__.global__cancel} />
            <Button onClick={() => handleUpdate(user_id, service_id) } label={__.global__save} />
          </ModalActions>
        </Modal>
      </Form>
    </>
)}

function mapStateToProps(state) {
  return {
    ...state.updatePassword,
    profile: state.profile || {},
    account_services: (state.account_services && state.account_services.list) || []
  }
}

export default connect(
  mapStateToProps,
  { ssoAddUserSubscription, ssoAccountServices}
)(withRouter(AddUserSubscription));
