import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import './modal.scss'

const ToggleContent = ({ toggle, content }) => {
  const [isShown, setIsShown] = useState(false);
  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  return (
    <>
      {toggle(show)}
      {isShown && content(hide)}
    </>
  );
};

export const useModal = (initialMode = false) => {
  const [modalOpen, setModalOpen] = useState(initialMode)
  const toggle = () => setModalOpen(!modalOpen)
  return [modalOpen, setModalOpen, toggle]
}

const ModalPortal = ({ children }) => (
  ReactDOM.createPortal(
    <>
      {children}
    </>,
    document.getElementById('modal-root')
  )
);

export const ModalContent = ({children }) => {
  return (
    <div className={`modal__container__content`}>
    {children}
    </div>
  )
}

export const ModalFooter = ({children }) => {
  return (
    <div className={`modal__container__footer`}>
      {children}
    </div>
  )
}


export const ModalActions= ({children }) => {
  return (
    <div className={`modal__container__footer modal__container__actions`}>
      {children}
    </div>
  )
}

export const Modal = ({active, children, title, toggle, className }) => {

  const stop = (e) => {
    e.stopPropagation();
  }

  if (!active) return null
  return (
  <ModalPortal>
    <div className={`modal ${className}`} onClick={toggle}>
      <div className={`modal__container`} onClick={stop}>
        <div className={`modal__container__header`}>
          <h3 className={`modal__container__header__text`}>
            {title}
          </h3>
        </div>
        {children}
      </div>
    </div>
  </ModalPortal>

  )}


const MModal = ({children, className, to}) => {
  const classes = `modal ${className}`
  return (
    <div className={`modal ${className}`}>
      <div className={`modal__container`}>
        <div className={`modal__container__header`}>Modal Header</div>
        <div className={`modal__container__content`}>
          {children}
        </div>
      </div>
    </div>
)}

