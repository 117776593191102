import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Form from 'components/form'
import Input from 'components/input'
import Button from 'components/button'
import Link from 'components/link'
import FormError from 'components/form-error'
import __ from "language";
import { ssoUpdatePassword } from 'api'
import { Modal, ModalContent, ModalActions, useModal } from 'components/modal'
import './update-password.scss'

const pwdIsGood = (pwd) => pwd.length >= 6
// && /[A-Z]/.test(pwd) && /[a-z]/.test(pwd) && /\d/.test(pwd);

const UpdatePassword = ({match, history, status, ssoUpdatePassword}) => {

  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [error, setError] = useState('')
  const [formIsValid, setFormValidity] = useState(false)
  const [activePasswordModal, setActivePasswordModal, togglePasswordModal] = useModal()

  useEffect( () => {
    setPassword('')
    setPassword2('')
  }, [activePasswordModal])

  const handleUpdate = (password) => {
    ssoUpdatePassword({password})
    togglePasswordModal()
  }

  return (
    <>
      <Link onClick={togglePasswordModal}>{__.auth__update_password}</Link>

      <Form>
        <Modal active={activePasswordModal} toggle={togglePasswordModal} title={__.auth__update_password}>
          <ModalContent>
            <Input required type="password" error={error} name="password" onChange={e => setPassword(e.target.value)} defaultValue={password} label={__.auth__password} placeholder={__.auth__password_placeholder}/>
            <Input required type="password" error={error} name="password2" onChange={e => setPassword2(e.target.value)} defaultValue={password2} label={__.auth__password_confirm} placeholder={__.auth__password_confirm_placeholder}/>
            <FormError error={error} />
          </ModalContent>
          <ModalActions>
            <Button theme="secondary" onClick={togglePasswordModal} label={__.global__cancel} />
            <Button disabled={!(pwdIsGood(password) && password === password2)} onClick={() => handleUpdate(password) } label={__.global__save} />
          </ModalActions>
        </Modal>
      </Form>
    </>
)}

function mapStateToProps(state) {
  return {
    ...state.updatePassword
  }
}

export default connect(
  mapStateToProps,
  { ssoUpdatePassword }
)(withRouter(UpdatePassword));
