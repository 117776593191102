import React from 'react'

import './checkblock.scss'

const Checkbock = ({title, children, check, ...rest }) => {
  return <div className="checkblock">
    <div className="checkblock__icon">
      {check ? 
        <img src={require('components/icons/icon-checkmark_filled.png')} /> 
        :
        ''
      }
    </div>
    <div className="checkblock__content">
      <span className="checkblock__content__title">{title}</span>
      <span className="checkblock__content__subtitle">{children}</span>
    </div>
  </div>
}

export default Checkbock
