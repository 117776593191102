import React from "react";

function IconLogout(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <title>
        log out
      </title>
      <path d="M3 3h8V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8v-2H3z"/>
      <path d="M19 10l-6-5v4H5v2h8v4l6-5z"/>
    </svg>
  );
}

IconLogout.defaultProps = {
  width: 16,
  height: 16
};

export default IconLogout;

