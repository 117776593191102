import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import __ from "language";
import { IconUser, IconUsers, IconAccount, IconEmail } from 'assets'
import './menu.scss'

const menu = [
  {to: 'mine-sider', icon: <IconUser />, label: __.profile_header},
  {to: 'mine-medlemmer', icon: <IconUsers />, label: __.profile_membership},
  {to: 'organisasjonsinnstillinger', icon: <IconAccount />, label: __.organization_settings},
]

export default withRouter(
  ({location, open}) => {
    const cls = `app__main__sidebar__menu app__main__sidebar__menu--${open ? 'opened' : 'closed'}`
    return <ul className={cls}>
      { menu.map( item => {
        const classes = [
          'app__main__sidebar__menu__item',
          location.pathname === item.to ? 'app__main__sidebar__menu__item--active' : ''
        ].join(' ')
        return (
          <Link className={classes} to={item.to} key={item.to}>{item.icon}<span>{item.label}</span></Link>
        )}
      )}
    </ul>
  }
)
