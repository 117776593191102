import { SSO_LOGIN_REFRESH_ERROR } from './refresh'

export const SSO_LOGIN = 'SSO_LOGIN'
export const SSO_LOGIN_SUCCESS = 'SSO_LOGIN_SUCCESS'
export const SSO_LOGIN_ERROR = 'SSO_LOGIN_ERROR'

export const login = (email, password) => {
  return {
    type: SSO_LOGIN,
    payload: {email, password}
  }
}

const initialState = {pending: false}
export default (state = initialState, action) => {
  switch(action.type){
    case SSO_LOGIN:
      return {
        ...state,
        pending: true,
        error: false
      }
      case SSO_LOGIN_SUCCESS:
        return {
          ...state,
          ...action.payload,
          pending: false,
          error: false
        }
        case SSO_LOGIN_ERROR:
          return {
            ...state,
            ...action.payload,
            pending: false,
          }
        case SSO_LOGIN_REFRESH_ERROR:
          return {
            ...initialState,
            ...action.payload,
            pending: false,
          }
  
      default:
        return state;
  }
}