import React from 'react'
import __ from 'language';
import FormMsg from 'components/form-msg'
import './form-error.scss'

const FormError = ({error}) => {
  if (!error) return null;
  const errorCode = error.message || error
  const msg = __[errorCode] || errorCode;

  return <FormMsg msg={msg} />;
}
export default FormError
