
export const SSO_PROFILE = 'SSO_PROFILE'
export const SSO_PROFILE_SUCCESS = 'SSO_PROFILE_SUCCESS'
export const SSO_PROFILE_ERROR = 'SSO_PROFILE_ERROR'

export const SSO_PROFILE_SAVE = 'SSO_PROFILE_SAVE'
export const SSO_PROFILE_SAVE_SUCCESS = 'SSO_PROFILE_SAVE_SUCCESS'
export const SSO_PROFILE_SAVE_ERROR = 'SSO_PROFILE_SAVE_ERROR'

export const login = (email, password) => {
  return {
    type: SSO_PROFILE,
    payload: {email, password}
  }
}

export default (state = {}, action) => {
  switch(action.type){
    case SSO_PROFILE:
    return {
      ...state,
      pending: true,
      error: false
    }
    case SSO_PROFILE_SUCCESS:
    return {
      ...state,
      ...action.payload,
      pending: false,
      error: false
    }
    case SSO_PROFILE_ERROR:
    return {
      ...state,
      ...action.payload,
      pending: false,
    }
    case SSO_PROFILE_SAVE:
    return {
      ...state,
      pending: true,
      error: false
    }
    case SSO_PROFILE_SAVE_SUCCESS:
    return {
      ...state,
      ...action.payload,
      pending: false,
      error: false
    }
    case SSO_PROFILE_SAVE_ERROR:
    return {
      ...state,
      ...action.payload,
      pending: false,
    }
    default:
    return state;
  }
}