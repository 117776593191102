import { combineReducers } from 'redux'
import login from './login'
import refresh from './refresh'
import resetPassword from './reset_password'
import profile from './profile'
import flow from './flow'
import subscription from './subscription'
import product from './product'
import client from './client'
import validation from './validation/index'
import user from './user/index'
import account from './account/index'
import updatePassword from './update_password'
import user_services from './user_services'
import account_members from './account_members'
import account_pending_members from './account_pending_members'
import account_pending_member_update from './account_pending_member_update'
import account_services from './account_services'
import registration from './registration'
import notification from './notification'

const appReducers = combineReducers({
  login,
  refresh,
  resetPassword,
  profile,
  flow,
  subscription,
  product,
  client,
  validation,
  user,
  account,
  updatePassword,
  user_services,
  account_members,
  account_pending_members,
  account_pending_member_update,
  account_services,
  registration,
  notification
})

export default (state, action) => {
  if (action.type === 'SSO_LOGOUT') {
    const {flow, client } = state
    state = {flow, client }
  }
  return appReducers(state, action)
}
