import React, { useState } from  'react'

export const MessageCtx = React.createContext([ [] , () => {}]);

export const MessageCtxProvider = ({children}) => { 
  const [message, setMessage] = useState([])

  const addMessage = (m, type = 'info') => {
    if (m && m.message)
    {
      return setMessage([m, ...message])
    }
    return setMessage([{message: m, type}, ...message])
  }

  return (
    <MessageCtx.Provider value={[message, addMessage]}>
      {children}
    </MessageCtx.Provider>
  ) 
}
