
export const SSO_REGISTRATION_STATE = 'SSO_REGISTRATION_STATE'
export const SSO_REGISTRATION_STATE_SUCCESS = 'SSO_REGISTRATION_STATE_SUCCESS'
export const SSO_REGISTRATION_STATE_ERROR = 'SSO_REGISTRATION_STATE_ERROR'
export const SSO_REGISTRATION_STATE_DEFAULT = 'SSO_REGISTRATION_STATE_DEFAULT'


export default (state = {pending: true}, action) => {
  switch(action.type){
    case SSO_REGISTRATION_STATE:
    return {
      ...state,
      pending: true,
      error: false
    }
    case SSO_REGISTRATION_STATE_SUCCESS:
    return {
      ...state,
      ...action.payload,
      pending: false,
      error: false
    }
    case SSO_REGISTRATION_STATE_ERROR:
    return {
      ...state,
      ...action.payload,
      pending: false,
    }
    default:
    return state;
  }
}