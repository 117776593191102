import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Form from 'components/form'
import Input from 'components/input'
import Button from 'components/button'
import FormError from 'components/form-error'
import __ from "language";
import { ssoUpdateContactSettings } from 'api'
import { Modal, ModalContent, ModalActions, useModal } from 'components/modal'
import { IconPencil } from "assets";
import './update-contact-settings.scss'


const UpdateContactSettings = ({account, ssoUpdateContactSettings}) => {

  const [address, setAddress] = useState(account.address)
  const [postnumber, setPostnumber] = useState(account.postnumber)
  const [city, setCity] = useState(account.city)
  const [firstname, setFirstName] = useState(account.firstname)
  const [lastname, setLastName] = useState(account.lastname)
  const [co, setCo] = useState(account.co)

  const [error, setError] = useState('')
  const [formIsValid, setFormValidity] = useState(false)
  const [active, setActive, toggle] = useModal()

  useEffect( () => {
  }, [active])

  const handleUpdate = (address, postnumber, city, firstname, lastname, co) => {
    ssoUpdateContactSettings({address, postnumber, city, firstname, lastname, co})
    toggle()
  }

  return (
    <>
      <Button theme="secondary" onClick={toggle} label={__.admin__update_contact_settings_button_label} icon={<IconPencil />}/>

      <Form>
        <Modal active={active} toggle={toggle} title={__.admin__update_contact_settings_button_label}>
          <ModalContent>
            <Input required type="text" error={error} name="firstname" onChange={e => setFirstName(e.target.value)} defaultValue={firstname} label={__.global__firstname}/>
            <Input required type="text" error={error} name="lastname" onChange={e => setLastName(e.target.value)} defaultValue={lastname} label={__.global__lastname}/>
            <Input required type="text" error={error} name="address" onChange={e => setAddress(e.target.value)} defaultValue={address} label={__.global__address}/>
            <Input required type="text" error={error} name="postnumber" onChange={e => setPostnumber(e.target.value)} defaultValue={postnumber} label={__.global__postnumber}/>
            <Input required type="text" error={error} name="city" onChange={e => setCity(e.target.value)} defaultValue={city} label={__.global__city}/>
            <Input required type="text" error={error} name="co" onChange={e => setCo(e.target.value)} defaultValue={co} label={__.global__co}/>
            <FormError error={error} />
          </ModalContent>
          <ModalActions>
            <Button theme="secondary" onClick={toggle} label={__.global__cancel} />
            <Button onClick={() => handleUpdate(address, postnumber, city, firstname, lastname, co) } label={__.global__save} />
          </ModalActions>
        </Modal>
      </Form>
    </>
)}

function mapStateToProps(state) {
  return {
    account: state.profile && state.profile.account || {}
  }
}

export default connect(
  mapStateToProps,
  { ssoUpdateContactSettings }
)(withRouter(UpdateContactSettings));
