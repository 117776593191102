import React from 'react';
import ProductFeatures from './product-features';
import ProductAvis from './product-avis';
import ProductTitle from './product-title';
import __ from 'language'
import './products.scss'

const Product = ({products}) => {
  const componentClass = 'product';
  const classes = [
    componentClass
  ].join(' ')

  const productOffer =
    products[0] && products[0].feature && products[0].feature[0] === "{" ?
      <ProductFeatures product={products[0]} />
      :
      <ProductAvis products={products} />

  return (
    <div className={classes}>
      <div className="content content--center">
        <ProductTitle product={products[0]} />
        <div className={`${componentClass}__description`} dangerouslySetInnerHTML={{__html: products[0].description}}></div>
        {productOffer}
      </div>
    </div>
  )
}

export default Product
