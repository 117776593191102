import React, { useState, useEffect } from 'react'

import Input from 'components/input'
import Button from 'components/button'
import Checkbox from 'components/checkbox'
import IconSupplier from "components/icons/icon-supplier";
import Instructions from 'components/instructions'
import { ssoOrgInfo } from 'api'
import OrgQuery from './org-query';
import RegistrationAccountAssign from './registration-account-assign';
import RegistrationAccountForm from './registration-account-form'
import __ from "language";
import './registration-account.scss'
import FormMsg from 'components/form-msg'
import registration from './registration';

const getOnlyInts = str =>
  str && 1 * str.match(/\d+/g).join('');



export default () => {

  const [orgnrTemp, setOrgnrTemp] = useState('')
  const [orgnr, setOrgnr] = useState('')
  const [org, setOrg] = useState({})
  const [skipOrg, setSkipOrg] = useState(false)

  useEffect( () => {
    if (!orgnrTemp) setOrg({})
  }, [orgnrTemp])


  return (
    <div className="registration-account">
      {orgnr && <OrgQuery orgnr={orgnr} setOrg={setOrg} /> }

      <Instructions>{__.registration_subscription_orgnumber_search_instruction}</Instructions>

      <div className="registration__get-org">
        <Checkbox onChange={e => setSkipOrg(e.target.checked)} defaultValue={skipOrg} label={__.registration_subscription_skip_org} />
      </div>

      { !skipOrg ? 
      <>

        <div className="registration__get-org">
          <Input required className="registration__get-org__input" icon={<IconSupplier />} type="number" name="orgnr" onChange={e => setOrgnrTemp(e.target.value)} defaultValue={orgnrTemp} label={__.global__org}/>
          <Button type="button" className="registration__get-org__btn" theme="secondary" disabled={!orgnrTemp} onClick={() => setOrgnr(getOnlyInts(orgnrTemp))} label={__.registration_get_company_info} />
        </div>
        
        {/* <Instructions emph>{__.registration_subscription_orgnumber_search_instruction_2}</Instructions> */}
        {org && (org.account_status === 'organisation__found__with__licenses' ) &&
          <div className="registration-account__search_account_exist">
            <Instructions>{__.registration_subscription_orgnumber_search_account_exist_feedback}</Instructions>
            <RegistrationAccountAssign org={org} />
          </div>
        }
        {org && (org.account_status === 'organisation__found__without__licenses' ) &&
          <div className="registration-account__without__licenses">
            <Instructions>{__.registration_subscription_orgnumber_search_breg_exist_feedback}</Instructions>
            <RegistrationAccountForm org={org} />
          </div>
        }
        { ( org && org.account_status === 'organisation__not__found' ) &&
          <div className="registration-account__not__found">
            <RegistrationAccountForm org={org} />
            <FormMsg msg={__.registration_subscription_orgnumber_search_breg_not_exist_feedback} />
          </div>
        }
        </>
      : 
        <div className="registration-account__not__found">
          <RegistrationAccountForm org={org} skip_org={true} />
        </div>
      }
    </div>
  )
}