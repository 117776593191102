import React from "react";

function IconSupplier(props) {
  return (
    <svg
      className={props.styleClass || "icon icon--supplier"}
      width={props.width}
      height="100%"
      viewBox="0 0 14 14"
      aria-labelledby="title"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Leverantør</title>
      <path
        id="Shape"
        d="m13.475 2.7175-6.125-2.625c-0.2625-0.0875-0.4375-0.0875-0.7 0l-6.125 2.625c-0.35 0.0875-0.525 0.4375-0.525 0.7875v7c0 0.35 0.175 0.7 0.525 0.7875l6.125 2.625c0.0875 0.0875 0.2625 0.0875 0.35 0.0875s0.2625 0 0.35-0.0875l6.125-2.625c0.35-0.175 0.525-0.4375 0.525-0.7875v-7c0-0.35-0.175-0.7-0.525-0.7875zm-6.475-0.875 3.9375 1.6625-3.9375 1.6625-3.9375-1.6625zm-5.25 2.975 4.375 1.8375v5.075l-4.375-1.8375zm6.125 7v-5.075l4.375-1.8375v5.075z"
        fill="inherit"
      />
    </svg>
  );
}

IconSupplier.defaultProps = {
  width: 14,
  height: 15
};

export default IconSupplier;
