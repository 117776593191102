// eslint-disable-next-line no-unused-vars
import React from 'react';
import { NavLink } from 'react-router-dom'

export default {
  account_members_header: 'Medlem administrasjon',
  account_members_section_existing_members_add_subscription: 'Legg til tjeneste',
  account_members_section_existing_members_invite: 'Legg til medlem',
  account_members_section_existing_members:'Eksisterende medlemmer',
  account_members_section_new_members_subtitle:'Du kan godkjenne eller avvise brukerforespørsler.',
  account_members_section_new_members:'Nye medlemmer',
  admin__add_user_subscription: 'Legg til tjeneste',
  admin__add_user: 'Legg til medlem',
  admin__update_contact_settings_button_label: 'Endre adresse',
  admin__update_deliver_address: 'Endre levering',
  admin__update_invoice_settings_button_label: 'Endre e-post',
  admin__update_invoice_settings_invoice_email: 'E-post for faktura',
  admin__update_user: 'Endre bruker navn',
  api_add_user_subscription__no_account: 'Det oppstod en feil. Kontoen mangler.',
  api_authlogin__failed__validation_error: 'Du må oppgi alle detaljene for å logge inn',
  api_error_token_claimed: 'Allerede brukt',
  api_error: 'Det oppstod en feil i kommunikasjonen med systemet.',
  api_update_invoice_settings__no_account: 'Det oppstod en feil. Kontoen mangler.',
  api_update_invoice_settings__validation_error: 'Det er ingen data som kan fullføre forespørselen din.',
  api_update_profile__validation_error: 'Det er ingen data som kan fullføre forespørselen din.',
  api_update_user_subscription__no_account: 'Det oppstod en feil. Kontoen mangler.',
  auth__authlogin__unknown: 'Manglende eller feil passord.',
  auth__data_policy_message_link: 'Les personvernerklæringen her',
  auth__data_policy_message: 'Jeg har lest og godkjent personvernerklæringen',
  auth__email__taken: <>Det finns allerede en bruker med denne e-postadressen! <NavLink to="logg-inn">Logg inn her</NavLink></>,
  auth__forgott_my_password: 'Jeg har glemt passordet mitt',
  auth__have_no_user_goto_registration: 'Opprett en konto',
  auth__password_confirm_placeholder: 'Gjenta nytt passord',
  auth__password_confirm: 'Gjenta passord',
  auth__password_placeholder: 'Lag nytt passord',
  auth__password_submit: 'Oppdater',
  auth__password: 'Passord',
  auth__resetpassword__invalid_token: 'Ugyldig eller gammel aktiveringslenke.',
  auth__resetpassword__token_claimed: 'Denne linken har allerede blitt brukt!',
  auth__update_password: 'Endre passord',
  contact_settings__general_information_about: 'Kontaktinformasjon',
  contact_settings__general_information: 'Kontaktinformasjon',
  contact_settings__routename: 'Kontaktinformasjon',
  global__address_active: 'Postadresse',
  global__address_placeholder: 'Gateadresse',
  global__address: 'Adresse',
  global__administrator: 'Administrator',
  global__back_to: 'Tillbake til',
  global__cancel: 'Avbryt',
  global__canceled_at: 'Gyldig til',
  global__city_placeholder: 'Oslo...',
  global__city: 'By',
  global__close: 'Lukk',
  global__co: 'c/o',
  global__comments_placeholder: 'Kommentar...',
  global__comments: 'Kommentar',
  global__company_placeholder: 'Selskap AS',
  global__company: 'Selskap',
  global__company_or_name: 'Selskap eller navn',
  global__delivery_address: 'Leveringsadresse',
  global__domain_placeholder: 'selskap.no',
  global__domain: 'Domene',
  global__email_active: 'mottaker e-post',
  global__email_placeholder: 'navn@selskap.no',
  global__email: 'E-post',
  global__firstname_placeholder: 'Navn',
  global__firstname: 'Fornavn',
  global__goto_to_service: 'Gå til tjenste',
  global__goto_to: 'Gå til',
  global__invoiceEmail_placeholder: 'faktura@selskap.no',
  global__invoiceEmail: 'Fakturaepost',
  global__lastname_placeholder: 'Navnesen',
  global__lastname: 'Etternavn',
  global__licenses: 'Lisenser',
  global__login: 'Logg inn',
  global__logout: 'Logg ut',
  global__menu_user_profile: 'Mine sider',
  global__name_placeholder: 'Navn Navnesen',
  global__name: 'Navn',
  global__order: 'Bestill',
  global__org_placeholder: 'Fyll i organisasjonsnummer...',
  global__org: 'Organisasjonsnummer',
  global__org_or_name: 'Organisasjonsnummer',
  global__password: 'Passord',
  global__phone_placeholder: 'Fyll i telefonnummer...',
  global__phone: 'Telefon',
  global__postnumber_placeholder: '1234',
  global__postnumber: 'Postnummer',
  global__read_more: 'Jeg vil vite mer',
  global__reference_placeholder: 'Fakturareferanse',
  global__activation_code_placeholder: 'Kampanjekode',
  global__reference: 'Fakturareferanse',
  global__activation_code: 'Kampanjekode',
  global__register: 'Registrere',
  global__save: 'Lagre',
  global__service: 'Tjeneste',
  global__tos_message_link: 'Bruksvilkår',
  global__tos_message: 'Jeg har lest og godkjent',
  global__tos_title: 'Bruksvilkår',
  global__user_profile: 'Mine sider',
  global__user: 'Bruker',
  global__zipcode_placeholder: '1234',
  my_services__copy_moresell_link: 'Gå til abonner-siden.',
  my_services__copy_moresell: 'Vil du lese mer om tjenestene våre?',
  my_services__no_subscription_buy: 'Men du kan kjøpe her',
  my_services__no_subscription_renew: 'Forny her',
  my_services__no_subscription: 'Du har intet aktivt abonnement for produktet.',
  my_subscriptions_account_header: 'Din konto',
  my_subscriptions_buy: 'Bestill',
  my_subscriptions_edit: 'Endring',
  my_subscriptions_header: 'Dine abonnement',
  my_subscriptions_licenses_to_app: 'Gå til',
  my_subscriptions_licenses: 'Lisenser',
  my_subscriptions_no_license: 'Ingen lisens',
  my_subscriptions_open_invoice: 'Fakturainnstillinger',
  my_subscriptions_order: 'Bestill lisens',
  my_subscriptions_reference: 'Fakturareferens',
  my_users_header: 'Dine bruker',
  organisation__found__with__licenses: <>Organisasjonen ble funnet! Du får tilgang til en av lisensene din organisasjon betaler for. Logg inn for å fortsette Pluss-bruker.</>,
  organisation__found__without__licenses: 'Organisasjonen ble funnet! Om du vil opprette en konto for din organisasjon og kjøpe lisenser så kan du gå videre nedenfor.',
  organisation__not__found: 'Ingen organisasjon ble funnet. Om du allikevel vil opprette en konto for din organisasjon og kjøpe lisenser, så kan du gjøre det nedenfor.',
  organization_settings__general_information_about: 'Fakturainnstillinger',
  organization_settings__general_information: 'Fakturainnstillinger',
  organization_settings__routename: 'Organisasjonsinnstillinger',
  organization_settings: 'Organisasjonsinnstillinger',
  pending_membership: 'Venter på medlemskap',
  pending_subscription: 'Venter på tilgang',
  period__per_month: 'per måned',
  period__per_week: 'per uke',
  period__per_year: 'per år',
  products__price_per_year: 'per år',
  products_list_header: 'Våre andre tjenester',
  profile_contact: 'Kontaktinformasjon',
  profile_general_information_about: 'Informasjon om deg og kontoen din',
  profile_general_information: 'Generell informasjon',
  profile_header: 'Din konto',
  profile_membership: 'Medlemmer',
  profile_organisation_about: '',
  profile_organisation_email: 'E-post',
  profile_organisation_responsible: 'Kontaktperson',
  profile_organisation: 'Din organisasjon',
  profile_services_about: 'Du har tilgang til disse tjenestene',
  profile_services: 'Tjenester',
  registration_account_already_member: 'Hei! Kontoen din er knyttet til følgende organisasjon',
  registration_account_continue_to_app: 'Fortsett til tjenestene',
  registration_account_form__submit_button: 'Lagre',
  registration_account_form__success: 'Kontoen din er nå opprettet.',
  registration_account_header: 'Opprett en PLUSS-konto',
  registration_account_instruction: 'Hei! Så bra at du vil opprette en Pluss-konto. La oss bare først sjekke om du har tilgang til en lisens fra din arbeidsgiver.',
  // registration_account_instruction: 'Registrer en konto for organisasjonen din.',
  registration_add_user_to_account: 'Koble din bruker til konto',
  registration_basis_already_exist_feedback: 'Du er allerede BASIS-bruker',
  registration_basis_completed_success: 'Du er herved registrert',
  registration_basis_instruction: 'Fyll inn navn og e-post for å begynne registreringen eller logg inn med eksisterende bruker',
  registration_basis_subtitle: '-Registrer en innlogging for din bruker. Slik at du får tilgang til våre digitale tjenester.',
  registration_basis_success: 'Vi har sendt en lenke til epost-adressen du oppga. Åpne eposten og klikk på lenken for å bekrefte epost-adressen.',
  registration_basis_title: '-Tjeneste  ',
  registration_basis_user_complete: 'Du er allerede innlogget med Basis-tilgang',
  registration_basis_user_form: 'Opprett en bruker',
  registration_basis_user_instructions: 'Gjennom din BASIS-konto kan du få tilgang til noen deler av tjenestene hos oss. Gå til sidene mine for å se detaljer om kontoen din.',
  registration_buy_license: 'Kjøp lisens',
  registration_datapolicy_header: 'Personvernerklæring',
  registration_email_exists: 'Denne epost-adressen er allerede tilknyttet en konto. Logg inn.',
  registration_first_account: 'Jeg er den første i min organisasjon til å kjøpe Kommunebarometeret.',
  registration_get_company_info: 'Hent inn organisasjonsinfo',
  registration_new_user: 'Opprett brukere',
  registration_org_header: 'PLUSS-konto, 5 lisenser',
  registration_org_instruction: 'Kostnaden for dette er 6 000 kr (5 x 1 200 kr) per år. Om ytterligere en person registrerer seg og oppgir organisasjonsnummeret nedenfor vil denne legges til i sluttsummen.',
  registration_org_submit: 'Opprett brukere og kjøp 5 lisenser',
  registration_org_your_org: 'Din organisasjon',
  registration_plus_account_form: 'Registrer en konto for organisasjonen din.',
  registration_plus_account_pending_message: 'Du er nå tilknyttet din organisasjons konto, men vi trenger godkjennelse fra deres kontoadministrator',
  registration_plus_subscription: 'Snart klart! Fyll ut fakturahenvisning og informasjon om abonnementet du bestilte. Klikk deretter på Bestill.',
  registration_plus_user_form: 'Registrer innloggingsinformasjon for brukeren din.',
  registration_set_password_instruction: 'Ett steg gjenstår: velg et passord og logg inn. Passordet ditt må være minst 6 tegn langt.',
  registration_set_password_success: 'Hei! Ditt passord er nå satt. Klikk på knappen for å fortsette.',
  registration_set_password_title: 'Velg et passord',
  registration_subscription_complete_feedback: 'Vi har opprettet ditt abonnement, og det er nå aktivert',
  registration_subscription_delivery_adress_instruction: 'Angi leveringsadresse',
  registration_subscription_form__submit_button: 'Lagre',
  registration_subscription_form__subscription_details: 'Tjeneste',
  registration_subscription_form__success: 'Vi har nå skapt ditt abonnement. Gå til mine sider for å ta i bruk dine tjenester.',
  registration_subscription_instruction: 'Fyll inn navn og e-post for å begynne registreringen eller logg inn med eksisterende bruker',
  registration_subscription_invoice_reference_instruction: 'Angi fakturareferanse',
  registration_subscription_activation_code_instruction: 'Angi kampanjekode',
  registration_subscription_add_invoice_address_instruction: 'Annen fakturaadresse',
  registration_subscription_order_validation_message: 'Du må oppgi alle detaljene i din leveringsadresse.',
  registration_subscription_order: 'Bestill',
  registration_subscription_orgnumber_search_account_added_button: 'Fortsett og bestill et produkt',
  registration_subscription_orgnumber_search_account_added_success: 'Du er herved lagt til organisasjonens konto. Mens vi venter på godkjenning fra din administrator kan du gå videre for å registrere ditt produkt.',
  registration_subscription_orgnumber_search_account_apply: 'Tilknytt',
  registration_subscription_orgnumber_search_account_created_success: 'Du har herved blitt administrator for din organisasjon og kan styre organisasjonens brukertilganger',
  registration_subscription_orgnumber_search_account_exist_feedback: 'Organisasjonen ble funnet! Du får tilgang til en av lisensene din organisasjon betaler for. Trykk "Fortsett" for å be om en av organisasjonens lisenser',
  registration_subscription_orgnumber_search_breg_exist_feedback: 'Organisasjonen ble funnet! Om du vil opprette en konto for din organisasjon og kjøpe lisenser så kan du gå videre nedenfor.',
  registration_subscription_orgnumber_search_breg_not_exist_feedback: 'Ingen organisasjon ble funnet. Kontakt oss her <a href=mailto  abonnement@kommunal-rapport.no>abonnement@kommunal-rapport.no</a> for mer informajon om hvordan man skaper en konto',
  registration_subscription_orgnumber_search_instruction: 'La oss bare sjekke om du har tilgang til en lisens fra din arbeidsgiver.',
  registration_subscription_orgnumber_search_instruction_2: 'Ta kontakt med oss på <a href="mailto:abonnement@kommunal-rapport.no">abonnement@kommunal-rapport.no</a> om du ikke skal knytte abonnementet till din virksomhet.',
  registration_subscription_pending_feedback: 'Vi har opprettet ditt abonnement, og venter nå på godkjenning fra deres kontoadministrator. Gå videre til "mine sider" for å ta del av tjenestene.',
  registration_subscription_skip_org: 'Registrera utan organisasjonsnummer',
  registration_subscription_subtitle: 'Hyggelig! For å kjøpe tjenesten, så er det noen steg videre.',
  registration_subscription_activation_code_subtitle: 'For å få denne tjenesten er det noen steg videre',
  registration_subscription_success: 'Vi har sendt en lenke til epost-adressen du oppga. Åpne eposten og klikk på lenken for å bekrefte epost-adressen.',
  registration_subscription_title: 'Tjeneste',
  registration_update_password_instruction: 'Velg et nytt passord. Passordet ditt må være minst 6 tegn langt.',
  registration_update_password_success: 'Hei! Ditt passord er nå satt. Klikk på knappen for å fortsette.',
  registration_update_password_title: 'Velg et nytt passord',
  registration_user__have_user_goto_login: 'Logg inn',
  registration_user_form__submit_button: 'Lagre',
  registration_user_form__success: 'Vi har sendt en lenke til epost-adressen du oppga. Åpne eposten og klikk på lenken for å bekrefte epost-adressen.',
  registration_user_header: 'Opprett en BASIS-konto',
  registration_user_instruction: 'Hei! Så bra at du vil opprette en BASIS-konto. Her får du et gratis innblikk i hvordan applikasjonen fungerer.',
  registration_user_instruction: 'Registrer en innlogging for din bruker. Slik at du får tilgang til våre digitale tjenester.',
  reset_password_complete_alt: 'Vi har sendt deg en e-post. Gå til din epost-klient og følg instruksjonene.',
  reset_password_complete: 'Vi har sendt deg en e-post. Gå til din epost-klient og følg instruksjonene.',
  reset_password_continue_to_login: 'Logg inn.',
  reset_password_have_login: 'Jeg vet passordet mitt. Logg inn.',
  reset_password_header: 'Glemt passordet ditt?',
  reset_password_instruction: 'Vi har fått en forespørsel om å gjenopprette ditt passord.',
  token_continue_to_login: 'Logg inn.',
  token_update_success: 'Hei! Ditt passord er nå endret. Klikk på knappen for å logge inn.',
  update_contact_settings_invoice_postnumber: 'Postnummer',

}